function Footer({togglePrivacyModal}) {
    return (
    <div className="footer-container">
                <footer className="app-footer">
                  <span className="email">Email: Logan@guessthestache.com</span>
                  <button
                    className="privacy-policy"
                    onClick={togglePrivacyModal}
                  >
                    Privacy Policy
                  </button>
                </footer>
              </div>)
};

export default Footer;