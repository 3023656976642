export default function InfoModal({ onClose, children }) {
    return (
        <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <button 
            onClick={onClose} 
            style={{
              border: 'none',
              background: 'transparent',
              cursor: 'pointer',
              color: 'inherit', // Inherits the font color from .modal-content or body.dark-mode .modal-content
              fontSize: '1.5rem',
              position: 'absolute',
              top: '0',
              right: '0',
              padding: '10px', // Adds some space for easier clicking
            }}
          >
            ×
          </button>
          {children}
        </div>
      </div>
    );
  }