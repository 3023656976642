import React, { useState, useEffect } from "react";
import "./App.css";
import { answers } from "./data/answers.js";
import logo from "./assets/GuessTheStacheLogo.png";
import InfoModal from "./components/Info";
import InfoIcon from "./assets/InfoIcon";
import PrivacyPolicyComponent from "./components/PrivacyPolicy";
import { extraNames } from "./data/extranames.js";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import ArchivePage from "./pages/ArchivePage.js";
import Footer from "./components/Footer.js";
import ArchiveGamePage from "./pages/ArchiveGamePage.js";
import Header from "./components/Header.js";
import StatsDisplay from "./components/StatsDisplay.js";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [guesses, setGuesses] = useState(
    parseInt(localStorage.getItem("guesses"), 10) || 0
  );
  const [correctGuess, setCorrectGuess] = useState(
    JSON.parse(localStorage.getItem("correctGuess")) || false
  );
  const [guessValue, setGuessValue] = useState("");
  const [modalContent, setModalContent] = useState(() => {
    try {
      const savedContent = localStorage.getItem("modalContent");
      // Check if savedContent is a valid JSON string
      return savedContent
        ? JSON.parse(savedContent)
        : { message: "", status: "" };
    } catch (error) {
      console.error("Error parsing modalContent from localStorage:", error);
      // If JSON parsing fails, return default object
      return { message: "", status: "" };
    }
  });
  const [gameLost, setGameLost] = useState(
    JSON.parse(localStorage.getItem("gameLost")) || false
  );
  const [guessesList, setGuessesList] = useState(
    JSON.parse(localStorage.getItem("guessesList")) || []
  );
  // const [isDarkMode, setIsDarkMode] = useState(
  //   JSON.parse(localStorage.getItem("darkMode")) || false
  // );
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isLastGuess, setIsLastGuess] = useState(false);
  const MAX_GUESSES = 4; // Or whatever your max guesses count is

  const [currentMustache, setCurrentMustache] = useState(null);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [gameEnded, setGameEnded] = useState(false);

  const updateStats = (ended) => {
    setGameEnded(ended);
  };

  function DisplayImage({
    src,
    alt,
    sourceUrl,
    license,
    gameStatus,
    wrongGuesses,
  }) {
    // gameStatus is true if either correctGuess or gameLost is true
    const [showSource, setShowSource] = useState(false);
    const [clickCount, setClickCount] = useState(0);
    const [timeoutId, setTimeoutId] = useState(null);
    const zoomLevel = 2.2 - wrongGuesses * 0.4;
    const imageStyle = {
      transform: `scale(${zoomLevel})`,
      transition: "transform 0.5s ease", // Smooth transition for zoom effect
      maxWidth: "none",
    };

    useEffect(() => {
      // Cleanup timeout on component unmount
      return () => {
        if (timeoutId) clearTimeout(timeoutId);
      };
    }, [timeoutId]);

    const handleSourceDisplay = () => {
      setShowSource(true);
      // Clear existing timeout if there is one
      if (timeoutId) clearTimeout(timeoutId);
      // Set new timeout to hide source after 3000ms
      const newTimeoutId = setTimeout(() => {
        setShowSource(false);
        setClickCount(0); // Reset click count when source is hidden
      }, 2000);
      setTimeoutId(newTimeoutId);
    };

    const handleClick = () => {
      if (!gameStatus) return; // Do nothing if the game is not in the correct state

      setClickCount((prevCount) => prevCount + 1);

      if (clickCount === 0) {
        handleSourceDisplay();
      } else if (clickCount === 1) {
        window.open(sourceUrl, "_blank"); // Open in new tab
        // Reset clickCount immediately to prevent multiple openings
        setClickCount(0);
      }
    };

    // Only enable hover functionality when gameStatus is true
    const handleMouseEnter = () => gameStatus && setShowSource(true);
    const handleMouseLeave = () => gameStatus && setShowSource(false);

    return (
      <div
        className={gameStatus ? "full-image-container" : "image-container"}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={src}
          alt={alt}
          style={gameStatus ? { height: "100%" } : imageStyle}
        />
        {showSource && gameStatus && (
          <div className="imageSourceContainer">
            <span className="imageSource">Source: {license}</span>
          </div>
        )}
      </div>
    );
  }

  const congratulationsMessages = [
    "You're a mustache genius!",
    "Spot on!",
    "Exquisite guess!",
  ];

  // Function to convert score to emojis
  const scoreToEmojis = (guessesList) => {
    let result = Array(4).fill("⬛️"); // Default to 4 blank squares

    guessesList.forEach((guess, index) => {
      result[index] = guess.isCorrect ? "✅" : "❌";
    });

    return result.join("");
  };

  const CopyToClipboardButton = ({ score }) => {
    const [buttonText, setButtonText] = React.useState("SHARE RESULTS");

    // Function to format the current date
    const formatDate = () => {
      const options = { year: "numeric", month: "long", day: "numeric" };
      const date = new Date(); // Current date and time
      date.setHours(date.getHours() - 6);
      return date.toLocaleDateString(undefined, options);
    };

    // Function to copy text to clipboard
    const copyTextToClipboard = async () => {
      const textToCopy = `GuessTheStache ${formatDate()}:\n${score}\nhttps://guessthestache.com`;
      try {
        await navigator.clipboard.writeText(textToCopy);
        setButtonText("Copied to clipboard!"); // Update button text to indicate success
        setTimeout(() => setButtonText("SHARE RESULTS"), 3000); // Revert after 3 seconds
      } catch (err) {
        console.error("Failed to copy: ", err);
        // Optionally, handle the error case with a different message or state
      }
    };

    return (
      <button className="share-button" onClick={copyTextToClipboard}>
        <span
          className={`share-span ${
            buttonText === "Copied to clipboard!" ? "tight-spacing" : ""
          }`}
        >
          {buttonText}
        </span>
      </button>
    );
  };
  // suggestion logic
  // Extracting all answers from each item in the answers array
  const wordBank = answers.flatMap((answer) => answer.answers[0]);

  const completeWordBank = [...new Set([...wordBank, ...extraNames])];

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.length > 0) {
      // Update regex to match any part of the string
      const regex = new RegExp(`${value}`, "i");
      const filteredSuggestions = completeWordBank.filter((name) =>
        regex.test(name)
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  // Rendering the suggestions
  const renderSuggestions = () => {
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul className="suggestions">
        {suggestions.slice(0, 7).map((suggestion, index) => (
          <li key={index} onClick={() => onSuggestionClick(suggestion)}>
            {suggestion}
          </li>
        ))}
      </ul>
    );
  };

  const onSuggestionClick = (value) => {
    setSuggestions([]);
    processGuess(value);
    setInputValue("");
  };

  // Function to generate multiple choice options
  const getMultipleChoiceOptions = () => {
    // Get the correct answer's first option
    const correctOption = currentMustache.answers[0];

    // Filter out the current answer to avoid repeating it in the options
    // const otherOptions = completeWordBank;

    // Shuffle the otherOptions to randomize the selection
    // const shuffledOptions = otherOptions.sort(() => 0.5 - Math.random());

    // Select three random options
    // const randomOptions = shuffledOptions.slice(0, 3);
    const randomOptions = currentMustache.multipleChoice;

    // Add the correct option and shuffle again
    const finalOptions = [correctOption, ...randomOptions].sort(
      () => 0.5 - Math.random()
    );

    return finalOptions;
  };

  useEffect(() => {
    if (guesses === MAX_GUESSES - 1) {
      setIsLastGuess(true);
    } else {
      setIsLastGuess(false);
    }
  }, [guesses]);

  const [currentMustacheId, setCurrentMustacheId] = useState(null);

  useEffect(() => {
    const fetchMustache = async () => {
      // Your fetch logic here...
      setIsLoading(true);
      try {
        const response = await fetch("/getCurrentMustache.php");
        const data = await response.json();
        if (response.ok && data.mustacheId !== undefined) {
          const mustacheData = answers[data.mustacheId];
          setCurrentMustacheId(data.mustacheId);
          if (mustacheData) {
            const lastVisitDate = localStorage.getItem("lastVisitDate");
            // Create a new Date object
            const todayUnedited = new Date();

            // subtract 6 hours from it
            todayUnedited.setHours(todayUnedited.getHours() - 6);

            // Convert to ISO string and slice to get the date part
            const today = todayUnedited.toISOString().slice(0, 10);
            // const today = new Date().toISOString().slice(0, 10);
            const lastMustacheId = localStorage.getItem("lastMustacheId");

            if (
              lastVisitDate !== today ||
              lastMustacheId !== data.mustacheId.toString()
            ) {
              // Reset game state to initial values if the visit day has changed
              // or the mustache ID is different from the last visit
              setGuesses(0);
              setCorrectGuess(false);
              setGameLost(false);
              setGuessesList([]);
              setModalContent("");

              // Update local storage to reflect the reset
              localStorage.setItem("guesses", "0");
              localStorage.setItem("correctGuess", JSON.stringify(false));
              localStorage.setItem("gameLost", JSON.stringify(false));
              localStorage.setItem("guessesList", JSON.stringify([]));
              localStorage.setItem("modalContent", "");
              localStorage.setItem(
                "lastMustacheId",
                data.mustacheId.toString()
              );
              localStorage.setItem("lastVisitDate", today);
            }

            setCurrentMustache(mustacheData);
          }
          setIsLoading(false);
        } else {
          console.error("Mustache data is undefined or request failed.");
        }
      } catch (error) {
        console.error("Error fetching the current mustache:", error);
      }

      // // For testing:
      // setIsLoading(false);
      // const mustacheData = answers[16];
      // setCurrentMustache(mustacheData);
    };

    fetchMustache().catch(console.error);
  }, []); // Assuming fetchMustache only needs to run on component mount

  useEffect(() => {
    const handleDarkModeChange = () => {
      document.body.classList.toggle("dark-mode", isDarkMode);
    };

    handleDarkModeChange();
  }, [isDarkMode]);

  useEffect(() => {
    // Persist state changes to localStorage
    localStorage.setItem("guesses", guesses.toString());
    localStorage.setItem("correctGuess", JSON.stringify(correctGuess));
    localStorage.setItem("gameLost", JSON.stringify(gameLost));
    localStorage.setItem("guessesList", JSON.stringify(guessesList));
    localStorage.setItem("darkMode", JSON.stringify(isDarkMode));
    localStorage.setItem("modalContent", JSON.stringify(modalContent));
  }, [guesses, correctGuess, gameLost, guessesList, isDarkMode, modalContent]);

  const processGuess = async (userGuess) => {
    if (!currentMustache) return; // Guard clause if currentMustache is not set

    const isCorrectAnswer = currentMustache.answers.some(
      (answer) => answer.toLowerCase() === userGuess.toLowerCase()
    );

    // Add the new guess to the guessesList with the correct/incorrect status
    const updatedGuessesList = [
      ...guessesList,
      { guess: userGuess, isCorrect: isCorrectAnswer },
    ];
    setGuessesList(updatedGuessesList);
    localStorage.setItem("guessesList", JSON.stringify(updatedGuessesList));

    // Check if the guess is correct and handle accordingly
    if (isCorrectAnswer) {
      setCorrectGuess(true);
      const congratsMessage = {
        message: `${getRandomMessage()}`,
        status: "correct", // Add a status to indicate the guess was correct
      };
      setModalContent(congratsMessage);
      localStorage.setItem("modalContent", congratsMessage);
      localStorage.setItem("correctGuess", JSON.stringify(true));
      // Reset guesses since the user guessed correctly
      setGuesses(0);
      localStorage.setItem("guesses", "0");
      await updateMustacheStats(currentMustacheId, guesses + 1, true);
      setGuessesList(updatedGuessesList);
      setGameEnded(true);
    } else {
      // Increment the number of guesses and check if the game is lost
      const newGuesses = guesses + 1;
      setGuesses(newGuesses);
      localStorage.setItem("guesses", newGuesses.toString());

      if (newGuesses >= MAX_GUESSES) {
        setGameLost(true);
        const gameLostMessage = `Better luck tomorrow! It was ${currentMustache.answers[0]}!`;
        setModalContent(gameLostMessage);
        localStorage.setItem("modalContent", JSON.stringify(gameLostMessage));
        localStorage.setItem("gameLost", JSON.stringify(true));
        await updateMustacheStats(currentMustacheId, guesses + 1, false);
        setGuessesList(updatedGuessesList);
        setGameEnded(true);
      }
    }

    setGuessValue(""); // Clear the input field after a guess
  };

  const updateMustacheStats = async (mustacheId, guessCount, isCorrect) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch('/updateMustacheStats.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mustacheId, guessCount, isCorrect }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to update mustache stats');
        }
        resolve();
      } catch (error) {
        console.error('Error updating mustache stats:', error);
        reject(error);
      }
    });
  };

  const handleGuess = (e) => {
    e.preventDefault();
    // processGuess(guessValue);
    processGuess(inputValue);
    setGuessValue("");
    setInputValue("");
  };

  const handleGuessOption = async (selectedOption) => {
    const isCorrectAnswer = selectedOption === currentMustache.answers[0]; // Check if the selected option matches the correct answer

    // Add the last guess to the guessesList with the correct/incorrect status
    const updatedGuessesList = [
      ...guessesList,
      { guess: selectedOption, isCorrect: isCorrectAnswer },
    ];
    setGuessesList(updatedGuessesList);
    localStorage.setItem("guessesList", JSON.stringify(updatedGuessesList));

    if (isCorrectAnswer) {
      setCorrectGuess(true);
      const randomCongratsMessage = {
        message: `${getRandomMessage()}`,
        status: "correct", // Add a status to indicate the guess was correct
      };
      setModalContent(randomCongratsMessage);
      localStorage.setItem("modalContent", randomCongratsMessage);
      localStorage.setItem("correctGuess", "true");
      await updateMustacheStats(currentMustacheId, 4, true); // Update stats for correct fourth guess
      setGuessesList(updatedGuessesList);
      setGameEnded(true);
      // You might want to handle game reset or navigation to a success screen here
    } else {
      setGameLost(true);
      const gameLostMessage = {
        message: `Better luck tomorrow!`,
        status: "incorrect", // Status for incorrect guesses
      };
      setModalContent(gameLostMessage);
      localStorage.setItem("modalContent", JSON.stringify(gameLostMessage));
      localStorage.setItem("gameLost", "true");
      await updateMustacheStats(currentMustacheId, 4, false);
      setGuessesList(updatedGuessesList);
      setGameEnded(true);
      // You might want to handle game reset or navigation to a failure screen here
    }

    // Increment guesses and clear the input
    const newGuesses = guesses + 1;
    setGuesses(newGuesses);
    localStorage.setItem("guesses", newGuesses.toString());
    setGuessValue(""); // Clear the input field after a guess

    // Since it's the last guess, consider resetting the game or navigating the user elsewhere
    // handleClearGuesses(true); // Optionally reset the game here if needed
  };

  const toggleAboutModal = () => setShowAboutModal(!showAboutModal);

  const getRandomMessage = () => {
    const randomIndex = Math.floor(
      Math.random() * congratulationsMessages.length
    );
    return congratulationsMessages[randomIndex];
  };

  const wrongGuesses = guessesList.filter((guess) => !guess.isCorrect).length;

  const toggleDarkMode = () => {
    const toggled = !isDarkMode;
    setIsDarkMode(toggled);
    localStorage.setItem("darkMode", JSON.stringify(toggled));
    document.body.classList.toggle("dark-mode", toggled);
  };

  const togglePrivacyModal = () => setShowPrivacyModal(!showPrivacyModal);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              <Header
                toggleAboutModal={toggleAboutModal}
                isDarkMode={isDarkMode}
                toggleDarkMode={toggleDarkMode}
              ></Header>

              {showAboutModal && (
                <InfoModal onClose={toggleAboutModal}>
                  <h2>How to Play</h2>
                  <p>
                    Guess whose mustache is shown. <br></br>
                    <br></br>
                    Everyday at midnight (CST) there will be a new mustache to
                    guess.
                    <br></br> <br></br>
                    You get 3 guesses, and then one multiple choice guess.
                    <br></br>
                    <br></br>
                    After each guess it will zoom out a bit to help see who the
                    person is.
                    <br></br>
                    <br></br>
                    NOTE: These images are from wikipedia commons so I don't run into copyright issues. Therefore, they may not be the best/clearest images of the celebrities. Sorry about that. It is what it is for now.
                  </p>
                </InfoModal>
              )}

              {showPrivacyModal && (
                <InfoModal onClose={togglePrivacyModal}>
                  <PrivacyPolicyComponent></PrivacyPolicyComponent>
                </InfoModal>
              )}

              {isLoading ? (
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              ) : currentMustache ? (
                <div className="message-box-image-container">
                  {modalContent && modalContent.message && (
                    <div
                      className={`message-box ${
                        modalContent.status === "correct"
                          ? "correct"
                          : "incorrect"
                      }`}
                    >
                      <p className="message">{modalContent.message}</p>
                      <div className="answer-container">
                        <p className="answer">
                          It was {currentMustache.answers[0]}!
                        </p>
                        <a
                          href={currentMustache.bio}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="who-btn">WHO?</button>
                        </a>
                      </div>
                      <div className="answer-btn-container">
                        <CopyToClipboardButton
                          score={scoreToEmojis(guessesList)}
                        />
                        <Link to="/archive" className="link-to-archive">
                          <button className="share-button">
                            <span className="share-span">SEE THE ARCHIVE</span>
                          </button>
                        </Link>
                      </div>
                      <StatsDisplay
                        mustacheId={currentMustacheId}
                        guessesList={guessesList}
                        gameEnded={correctGuess || gameLost}
                      />
                    </div>
                  )}
                  <DisplayImage
                    src={
                      correctGuess || gameLost
                        ? currentMustache.fullSrc
                        : currentMustache.mustacheSrc
                    }
                    alt="Daily Mustache"
                    sourceUrl={currentMustache.source}
                    license={currentMustache.license}
                    gameStatus={correctGuess || gameLost}
                    wrongGuesses={wrongGuesses}
                  />
                </div>
              ) : (
                <p>Mustache data is not available.</p>
              )}

              {!correctGuess && !gameLost && !isLastGuess && (
                <div
                  className={`searchBox ${
                    suggestions.length > 0 ? "show-rectangle" : ""
                  }`}
                >
                  <form onSubmit={handleGuess}>
                    <input
                      className="searchInput"
                      type="text"
                      placeholder="Guess"
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                    {renderSuggestions()}
                    <button className="searchButton" type="submit">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="29"
                        viewBox="0 0 29 29"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_2_17)">
                          <g filter="url(#filter0_d_2_17)">
                            <path
                              d="M23.7953 23.9182L19.0585 19.1814M19.0585 19.1814C19.8188 18.4211 20.4219 17.5185 20.8333 16.5251C21.2448 15.5318 21.4566 14.4671 21.4566 13.3919C21.4566 12.3167 21.2448 11.252 20.8333 10.2587C20.4219 9.2653 19.8188 8.36271 19.0585 7.60242C18.2982 6.84214 17.3956 6.23905 16.4022 5.82759C15.4089 5.41612 14.3442 5.20435 13.269 5.20435C12.1938 5.20435 11.1291 5.41612 10.1358 5.82759C9.1424 6.23905 8.23981 6.84214 7.47953 7.60242C5.94407 9.13789 5.08145 11.2204 5.08145 13.3919C5.08145 15.5634 5.94407 17.6459 7.47953 19.1814C9.01499 20.7168 11.0975 21.5794 13.269 21.5794C15.4405 21.5794 17.523 20.7168 19.0585 19.1814Z"
                              stroke="white"
                              strokeWidth="3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </g>
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_2_17"
                            x="-0.418549"
                            y="3.70435"
                            width="29.7139"
                            height="29.7139"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            ></feFlood>
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            ></feColorMatrix>
                            <feOffset dy="4"></feOffset>
                            <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                            <feComposite
                              in2="hardAlpha"
                              operator="out"
                            ></feComposite>
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            ></feColorMatrix>
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2_17"
                            ></feBlend>
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2_17"
                              result="shape"
                            ></feBlend>
                          </filter>
                          <clipPath id="clip0_2_17">
                            <rect
                              width="28.0702"
                              height="28.0702"
                              fill="white"
                              transform="translate(0.403503 0.526367)"
                            ></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </form>
                </div>
              )}

              {isLastGuess && currentMustache && (
                <div className="multiple-choice-container">
                  {getMultipleChoiceOptions().map((option, index) => (
                    <button
                      key={index}
                      className="multiple-choice-btn"
                      onClick={() => handleGuessOption(option)}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              )}

              {!correctGuess && !gameLost && guesses < MAX_GUESSES && (
                <p className={`guesses-remaining ${isDarkMode ? "dark" : ""}`}>
                  {MAX_GUESSES - guesses} GUESS
                  {MAX_GUESSES - guesses === 1 ? "" : "ES"} REMAINING
                </p>
              )}

              {(guesses > 0 || correctGuess || gameLost) && (
                <div className="guesses-list-title">GUESSES:</div>
              )}
              <div className="guesses-list">
                {guessesList.map((item, index) => (
                  <div key={index}>
                    {item.guess}{" "}
                    <span
                      className={
                        item.isCorrect ? "guess-correct" : "guess-wrong"
                      }
                    >
                      {item.isCorrect ? "✓" : "x"}
                    </span>
                  </div>
                ))}
              </div>
              <Footer togglePrivacyModal={togglePrivacyModal}></Footer>
            </div>
          }
        />
        <Route path="/archive" element={<ArchivePage />} />
        <Route path="/archive-game" element={<ArchiveGamePage />} />
      </Routes>
    </BrowserRouter>
  );
}

// function Modal({ isOpen, onClose, children }) {
//   if (!isOpen) return null;

//   return (
//     <div className="modal-overlay">
//       <div className="modal-content">
//         <button onClick={onClose}>Close</button>
//         {children}
//       </div>
//     </div>
//   );
// }

export default App;
