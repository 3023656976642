import React, { useState, useEffect } from "react";
import "../App.css";
import { answers } from "../data/answers.js";
import logo from "../assets/GuessTheStacheLogo.png";
import InfoModal from "../components/Info";
import InfoIcon from "../assets/InfoIcon";
import PrivacyPolicyComponent from "../components/PrivacyPolicy";
import { Link } from "react-router-dom";
import Footer from "../components/Footer.js";

function ArchivePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [days, setDays] = useState([]);
  const [archiveGuesses, setarchiveGuesses] = useState(
    parseInt(localStorage.getItem("archiveGuesses"), 10) || 0
  );
  const [correctArchiveGuess, setcorrectArchiveGuess] = useState(
    JSON.parse(localStorage.getItem("correctArchiveGuess")) || false
  );
  const [archiveGuessValue, setarchiveGuessValue] = useState("");
  const [archiveModalContent, setarchiveModalContent] = useState(() => {
    try {
      const savedContent = localStorage.getItem("archiveModalContent");
      // Check if savedContent is a valid JSON string
      return savedContent
        ? JSON.parse(savedContent)
        : { message: "", status: "" };
    } catch (error) {
      console.error(
        "Error parsing archiveModalContent from localStorage:",
        error
      );
      // If JSON parsing fails, return default object
      return { message: "", status: "" };
    }
  });
  const [archiveGameLost, setarchiveGameLost] = useState(
    JSON.parse(localStorage.getItem("archiveGameLost")) || false
  );
  const [archiveGuessesList, setarchiveGuessesList] = useState(
    JSON.parse(localStorage.getItem("archiveGuessesList")) || []
  );
  //   const [isDarkMode, setIsDarkMode] = useState(
  //     JSON.parse(localStorage.getItem("darkMode")) || false
  //   );
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [archiveIsLastGuess, setarchiveIsLastGuess] = useState(false);
  const MAX_archiveGuesses = 4; // Or whatever your max archiveGuesses count is

  const [archiveCurrentMustache, setarchiveCurrentMustache] = useState(null);
  const [currentMustacheIndex, setCurrentMustacheIndex] = useState(null);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const congratulationsMessages = [
    "You're a mustache genius!",
    "Spot on!",
    "Exquisite guess!",
  ];

  useEffect(() => {
    if (archiveGuesses === MAX_archiveGuesses - 1) {
      setarchiveIsLastGuess(true);
    } else {
      setarchiveIsLastGuess(false);
    }
  }, [archiveGuesses]);

  useEffect(() => {
    const fetchDays = async () => {
      try {
        const response = await fetch("/getDays.php");
        const data = await response.json();
        if (response.ok) {
          setDays(data.days);
          setIsLoading(false);
        } else {
          console.error("Data is undefined or request failed.");
        }
      } catch (error) {
        console.error("Error fetching days:", error);
      }
    };

    fetchDays().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchMustache = async () => {
      try {
        const response = await fetch("/getCurrentMustache.php");
        const data = await response.json();
        if (response.ok && data.mustacheId !== undefined) {
          const mustacheData = answers[data.mustacheId];
          if (mustacheData) {
            setIsLoading(false);
            setCurrentMustacheIndex(data.mustacheId);
          }
        } else {
          console.error("Mustache data is undefined or request failed.");
        }
      } catch (error) {
        console.error("Error fetching the current mustache:", error);
      }

      //For testing:
      // setIsLoading(false);
      // setCurrentMustacheIndex(143);
    };

    fetchMustache().catch(console.error);
  }, []); // Assuming fetchMustache only needs to run on component mount

  useEffect(() => {
    const handleDarkModeChange = () => {
      document.body.classList.toggle("dark-mode", isDarkMode);
    };

    handleDarkModeChange();
  }, [isDarkMode]);

  useEffect(() => {
    // Persist state changes to localStorage
    localStorage.setItem("archiveGuesses", archiveGuesses.toString());
    localStorage.setItem(
      "correctArchiveGuess",
      JSON.stringify(correctArchiveGuess)
    );
    localStorage.setItem("archiveGameLost", JSON.stringify(archiveGameLost));
    localStorage.setItem(
      "archiveGuessesList",
      JSON.stringify(archiveGuessesList)
    );
    localStorage.setItem("darkMode", JSON.stringify(isDarkMode));
    localStorage.setItem(
      "archiveModalContent",
      JSON.stringify(archiveModalContent)
    );
  }, [
    archiveGuesses,
    correctArchiveGuess,
    archiveGameLost,
    archiveGuessesList,
    isDarkMode,
    archiveModalContent,
  ]);

  const toggleAboutModal = () => setShowAboutModal(!showAboutModal);

  const toggleDarkMode = () => {
    const toggled = !isDarkMode;
    setIsDarkMode(toggled);
    localStorage.setItem("darkMode", JSON.stringify(toggled));
    document.body.classList.toggle("dark-mode", toggled);
  };

  const togglePrivacyModal = () => setShowPrivacyModal(!showPrivacyModal);

  const handleButtonClick = (index) => {
    localStorage.setItem(`button${index}Clicked`, true);
  };

  const isButtonClicked = (index) => {
    return localStorage.getItem(`button${index}Clicked`);
  };

  return (
    <div className="App">
      <div className="top-row">
        <div className="top-row-left"></div>
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        </div>
        <div className="header-options">
          <Link to="/">
            <button className="archive-btn">DAILY</button>
          </Link>
          <button className="about-btn" onClick={toggleAboutModal}>
            <InfoIcon className="about-icon" />
          </button>
        </div>
      </div>

      <div className="archive-main-screen-container">
        <div className="archive-title">ARCHIVE:</div>
        <div className="archive-btn-container">
          {days.map(day => (
            <Link
              to="/archive-game"
              state={{ day: day }} // Pass the day as state
              key={day}
            >
              <button className="archive-db-btn">
                Day {day}
              </button>
            </Link>
          ))}
        </div>
      </div>

      {isLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}

      {showAboutModal && (
        <InfoModal onClose={toggleAboutModal}>
          <h2>How to Play</h2>
          <p>
            Guess whose mustache is shown. <br></br>
            <br></br>
            Everyday at midnight (CST) there will be a new mustache to guess.
            <br></br> <br></br>
            You get 3 guesses, and then one multiple choice guess.
            <br></br>
            <br></br>
            After each guess it will zoom out a bit to help see who the person
            is.
          </p>
        </InfoModal>
      )}

      {showPrivacyModal && (
        <InfoModal onClose={togglePrivacyModal}>
          <PrivacyPolicyComponent></PrivacyPolicyComponent>
        </InfoModal>
      )}

      <Footer togglePrivacyModal={togglePrivacyModal}></Footer>
    </div>
  );
}

export default ArchivePage;
