import React from "react";

const PrivacyPolicyComponent = () => {
  return (
    <div className="privacy-policy-modal">
      <h1>Privacy Policy for GuessTheStache.com</h1>
      <p>Last updated: Feb 23, 2024</p>
      <h2>Introduction</h2>
      <p>
        Welcome to GuessTheStache. We are committed to protecting your privacy.
        This Privacy Policy explains how we collect, use, and safeguard
        information on GuessTheStache.com.
      </p>
      <h2>Information We Collect</h2>
      <h4>Local Storage on Your Device</h4>
      <p>
        We use local storage technology to enhance your experience by saving
        your game progress and preferences directly on your device. This allows
        you to return to the game without losing your progress. We do not use
        local storage to collect personal information about you.
      </p>
      <h4>Cookies</h4>
      <p>We use your cookies to get general information on site traffic. Google Analytics is used to do this.</p>
      <h2>How We Use Your Information</h2>
      <p>
        The information stored in your device's local storage is used
        exclusively for the following purposes:
        <li>To save your game progress.</li>
        <li>
          To remember your preferences and settings for a personalized
          experience.
        </li>
      </p>
      <p>
        The analytics information from cookies is received so that we can analyze site traffic.
      </p>
      <h2>Sharing of Your Information</h2>
      <p>
        We do not share any information stored in your local storage with third
        parties. Your game progress and preferences remain confidential and are
        accessed only by you.
      </p>
      <p>We do not share any information from cookies.</p>
      <h2>Your Rights and Choices</h2>
      <p>
        Since we do not collect personal information, traditional rights related
        to personal data may not be applicable. However, you can manage local
        storage on your device through your web browser settings, including
        deleting data stored by our Site.
      </p>
      <h2>Security Measures</h2>
      <p>We implement reasonable measures to ensure the security of information stored in your local storage. However, please be aware that no security system is impervious to risk.</p>
      <h2>Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We encourage you to review this policy periodically for any changes.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact me at Logan@guessthestache.com</p>
    </div>
  );
};

export default PrivacyPolicyComponent;