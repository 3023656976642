import React, { useState, useEffect } from "react";

const StatsDisplay = ({ mustacheId, guessesList, gameEnded }) => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        // Add a delay of 1000ms (1 second) before making the API call
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        const response = await fetch(`/getMustacheStats.php?mustacheId=${mustacheId}`);
        const data = await response.json();
        setStats(data);
      } catch (error) {
        console.error("Error fetching mustache stats:", error);
      }
    };
  
    if (gameEnded) {
      fetchStats();
    }
  }, [mustacheId, gameEnded]);

  if (!stats) {
    return null;
  }

  const totalGuesses = stats.firstGuessCorrect + stats.secondGuessCorrect + stats.thirdGuessCorrect + stats.fourthGuessCorrect + stats.guessedWrong;

  const formatPercentage = (value) => {
    if (totalGuesses === 0) {
      return "0%";
    }
    return `${((value / totalGuesses) * 100).toFixed(0)}%`;
  };

  return (
    <div className="stats-display">
      <h3 className="stats-title">MUS-STATS</h3>
      <div className="stats-item">
        Guess 1: {formatPercentage(stats.firstGuessCorrect)}
      </div>
      <div className="stats-item">
        Guess 2: {formatPercentage(stats.secondGuessCorrect)}
      </div>
      <div className="stats-item">
        Guess 3: {formatPercentage(stats.thirdGuessCorrect)}
      </div>
      <div className="stats-item">
        Guess 4: {formatPercentage(stats.fourthGuessCorrect)}
      </div>
      <div className="stats-item">
        Incorrect Guesses: {formatPercentage(stats.guessedWrong)}
      </div>
    </div>
  );
};

export default StatsDisplay;