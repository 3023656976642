import React, { useState, useEffect } from "react";
import "../App.css";
import { answers } from "../data/answers.js";
import logo from "../assets/GuessTheStacheLogo.png";
import InfoModal from "../components/Info";
import InfoIcon from "../assets/InfoIcon";
import PrivacyPolicyComponent from "../components/PrivacyPolicy";
import { extraNames } from "../data/extranames.js";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import StatsDisplay from "../components/StatsDisplay";

function ArchiveGamePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [archiveGuesses, setarchiveGuesses] = useState(
    parseInt(localStorage.getItem("archiveGuesses"), 10) || 0
  );
  const [correctArchiveGuess, setcorrectArchiveGuess] = useState(
    JSON.parse(localStorage.getItem("correctArchiveGuess")) || false
  );
  const [archiveGuessValue, setarchiveGuessValue] = useState("");
  const [archiveModalContent, setarchiveModalContent] = useState(() => {
    try {
      const savedContent = localStorage.getItem("archiveModalContent");
      // Check if savedContent is a valid JSON string
      return savedContent
        ? JSON.parse(savedContent)
        : { message: "", status: "" };
    } catch (error) {
      console.error(
        "Error parsing archiveModalContent from localStorage:",
        error
      );
      // If JSON parsing fails, return default object
      return { message: "", status: "" };
    }
  });
  const [archiveGameLost, setarchiveGameLost] = useState(
    JSON.parse(localStorage.getItem("archiveGameLost")) || false
  );
  const [archiveGuessesList, setarchiveGuessesList] = useState(
    JSON.parse(localStorage.getItem("archiveGuessesList")) || []
  );
  //   const [isDarkMode, setIsDarkMode] = useState(
  //     JSON.parse(localStorage.getItem("darkMode")) || false
  //   );
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [archiveIsLastGuess, setarchiveIsLastGuess] = useState(false);
  const MAX_archiveGuesses = 4; // Or whatever your max archiveGuesses count is

  const [archiveCurrentMustache, setarchiveCurrentMustache] = useState(null);
  const [currentMustacheIndex, setCurrentMustacheIndex] = useState(null);
  const [mustacheId, setMustacheId] = useState(null);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [archiveInputValue, setarchiveInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const location = useLocation();
  var { day } = location.state || {};
  const [gameEnded, setGameEnded] = useState(false);

  useEffect(() => {
    if (day !== undefined) {
      fetchMustacheIdByDay(day);
    }
  }, [day]);

  const fetchMustacheIdByDay = async (day) => {
    try {
      const response = await fetch(`/getMustacheByDay.php?day=${day}`);
      const data = await response.json();
      if (response.ok && data.mustacheId !== undefined) {
        setMustacheId(data.mustacheId);
        const mustacheData = answers[data.mustacheId];
        setarchiveCurrentMustache(mustacheData);
        setIsLoading(false);
      } else {
        console.error("Mustache data is undefined or request failed.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching mustache ID:", error);
      setIsLoading(false);
    }
  };

  const updateStats = (ended) => {
    setGameEnded(ended);
  };

  function DisplayImage({
    src,
    alt,
    sourceUrl,
    license,
    gameStatus,
    wrongarchiveGuesses,
  }) {
    // gameStatus is true if either correctArchiveGuess or archiveGameLost is true
    const [archiveShowSource, setarchiveShowSource] = useState(false);
    const [clickCount, setClickCount] = useState(0);
    const [timeoutId, setTimeoutId] = useState(null);
    const zoomLevel = 2.2 - wrongarchiveGuesses * 0.4;
    const archiveImageStyle = {
      transform: `scale(${zoomLevel})`,
      transition: "transform 0.5s ease", // Smooth transition for zoom effect
      maxWidth: "none",
    };

    useEffect(() => {
      // Cleanup timeout on component unmount
      return () => {
        if (timeoutId) clearTimeout(timeoutId);
      };
    }, [timeoutId]);

    const handleSourceDisplay = () => {
      setarchiveShowSource(true);
      // Clear existing timeout if there is one
      if (timeoutId) clearTimeout(timeoutId);
      // Set new timeout to hide source after 3000ms
      const newTimeoutId = setTimeout(() => {
        setarchiveShowSource(false);
        setClickCount(0); // Reset click count when source is hidden
      }, 2000);
      setTimeoutId(newTimeoutId);
    };

    const handleClick = () => {
      if (!gameStatus) return; // Do nothing if the game is not in the correct state

      setClickCount((prevCount) => prevCount + 1);

      if (clickCount === 0) {
        handleSourceDisplay();
      } else if (clickCount === 1) {
        window.open(sourceUrl, "_blank"); // Open in new tab
        // Reset clickCount immediately to prevent multiple openings
        setClickCount(0);
      }
    };

    // Only enable hover functionality when gameStatus is true
    const handleMouseEnter = () => gameStatus && setarchiveShowSource(true);
    const handleMouseLeave = () => gameStatus && setarchiveShowSource(false);

    return (
      <div
        className={gameStatus ? "full-image-container" : "image-container"}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={src}
          alt={alt}
          style={gameStatus ? { height: "100%" } : archiveImageStyle}
        />
        {archiveShowSource && gameStatus && (
          <div className="imageSourceContainer">
            <span className="imageSource">Source: {license}</span>
          </div>
        )}
      </div>
    );
  }

  const congratulationsMessages = [
    "You're a mustache genius!",
    "Spot on!",
    "Exquisite guess!",
  ];

  // suggestion logic
  // Extracting all answers from each item in the answers array
  const wordBank = answers.flatMap((answer) => answer.answers[0]);
  // Assuming extraNames contains additional names you want to include
  const completeWordBank = [...new Set([...wordBank, ...extraNames])];

  const handleInputChange = (e) => {
    const value = e.target.value;
    setarchiveInputValue(value);

    if (value.length > 0) {
      // Update regex to match any part of the string
      const regex = new RegExp(`${value}`, "i");
      const filteredSuggestions = completeWordBank.filter((name) =>
        regex.test(name)
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  // Rendering the suggestions
  const renderSuggestions = () => {
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul className="suggestions">
        {suggestions.slice(0, 7).map((suggestion, index) => (
          <li key={index} onClick={() => onSuggestionClick(suggestion)}>
            {suggestion}
          </li>
        ))}
      </ul>
    );
  };

  const onSuggestionClick = (value) => {
    setSuggestions([]);
    processGuess(value);
    setarchiveInputValue("");
  };

  // Function to generate multiple choice options
  const getMultipleChoiceOptions = () => {
    // Get the correct answer's first option
    const correctOption = archiveCurrentMustache.answers[0];

    // const randomOptions = shuffledOptions.slice(0, 3);
    const randomOptions = archiveCurrentMustache.multipleChoice;

    // Add the correct option and shuffle again
    const finalOptions = [correctOption, ...randomOptions].sort(
      () => 0.5 - Math.random()
    );

    return finalOptions;
  };

  useEffect(() => {
    if (archiveGuesses === MAX_archiveGuesses - 1) {
      setarchiveIsLastGuess(true);
    } else {
      setarchiveIsLastGuess(false);
    }
  }, [archiveGuesses]);

  useEffect(() => {
    // clear cache
    setarchiveGuesses(0);
    setcorrectArchiveGuess(false);
    setarchiveGameLost(false);
    setarchiveGuessesList([]);
    setarchiveModalContent("");

    // Update local storage to reflect the reset
    localStorage.setItem("archiveGuesses", "0");
    localStorage.setItem("correctArchiveGuesses", JSON.stringify(false));
    localStorage.setItem("archiveGameLost", JSON.stringify(false));
    localStorage.setItem("archiveGuessesList", JSON.stringify([]));
    localStorage.setItem("archiveModalContent", "");

    const mustacheData = answers[mustacheId]; // whatever was pressed
    setarchiveCurrentMustache(mustacheData);
    setIsLoading(false);
  }, [mustacheId]);

  useEffect(() => {
    const handleDarkModeChange = () => {
      document.body.classList.toggle("dark-mode", isDarkMode);
    };

    handleDarkModeChange();
  }, [isDarkMode]);

  useEffect(() => {
    // Persist state changes to localStorage
    localStorage.setItem("archiveGuesses", archiveGuesses.toString());
    localStorage.setItem(
      "correctArchiveGuess",
      JSON.stringify(correctArchiveGuess)
    );
    localStorage.setItem("archiveGameLost", JSON.stringify(archiveGameLost));
    localStorage.setItem(
      "archiveGuessesList",
      JSON.stringify(archiveGuessesList)
    );
    localStorage.setItem("darkMode", JSON.stringify(isDarkMode));
    localStorage.setItem(
      "archiveModalContent",
      JSON.stringify(archiveModalContent)
    );
  }, [
    archiveGuesses,
    correctArchiveGuess,
    archiveGameLost,
    archiveGuessesList,
    isDarkMode,
    archiveModalContent,
  ]);

  const updateMustacheStats = async (mustacheId, guessCount, isCorrect) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch("/updateMustacheStats.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mustacheId, guessCount, isCorrect }),
        });

        if (!response.ok) {
          throw new Error("Failed to update mustache stats");
        }
        resolve();
      } catch (error) {
        console.error("Error updating mustache stats:", error);
        reject(error);
      }
    });
  };

  const processGuess = async (userGuess) => {
    if (!archiveCurrentMustache) return; // Guard clause if archiveCurrentMustache is not set

    const isCorrectAnswer = archiveCurrentMustache.answers.some(
      (answer) => answer.toLowerCase() === userGuess.toLowerCase()
    );

    // Add the new guess to the archiveGuessesList with the correct/incorrect status
    const updatedarchiveGuessesList = [
      ...archiveGuessesList,
      { guess: userGuess, isCorrect: isCorrectAnswer },
    ];
    setarchiveGuessesList(updatedarchiveGuessesList);
    localStorage.setItem(
      "archiveGuessesList",
      JSON.stringify(updatedarchiveGuessesList)
    );

    // Check if the guess is correct and handle accordingly
    if (isCorrectAnswer) {
      setcorrectArchiveGuess(true);
      const congratsMessage = {
        message: `${getRandomMessage()}`,
        status: "correct", // Add a status to indicate the guess was correct
      };
      setarchiveModalContent(congratsMessage);
      localStorage.setItem("archiveModalContent", congratsMessage);
      localStorage.setItem("correctArchiveGuess", JSON.stringify(true));
      // Reset archiveGuesses since the user guessed correctly
      setarchiveGuesses(0);
      localStorage.setItem("archiveGuesses", "0");
      await updateMustacheStats(mustacheId, archiveGuesses + 1, true);
      setarchiveGuessesList(updatedarchiveGuessesList);
      setGameEnded(true);
    } else {
      // Increment the number of archiveGuesses and check if the game is lost
      const newarchiveGuesses = archiveGuesses + 1;
      setarchiveGuesses(newarchiveGuesses);
      localStorage.setItem("archiveGuesses", newarchiveGuesses.toString());

      if (newarchiveGuesses >= MAX_archiveGuesses) {
        setarchiveGameLost(true);
        const archiveGameLostMessage = `Not this time! It was ${archiveCurrentMustache.answers[0]}!`;
        setarchiveModalContent(archiveGameLostMessage);
        localStorage.setItem(
          "archiveModalContent",
          JSON.stringify(archiveGameLostMessage)
        );
        localStorage.setItem("archiveGameLost", JSON.stringify(true));
        await updateMustacheStats(mustacheId, newarchiveGuesses, false);
        setarchiveGuessesList(updatedarchiveGuessesList);
        setGameEnded(true);
      }
    }

    setarchiveGuessValue(""); // Clear the input field after a guess
  };

  const handleGuess = (e) => {
    e.preventDefault();
    // processGuess(archiveGuessValue);
    processGuess(archiveInputValue);
    setarchiveGuessValue("");
  };

  const handleGuessOption = async (selectedOption) => {
    const isCorrectAnswer =
      selectedOption === archiveCurrentMustache.answers[0]; // Check if the selected option matches the correct answer

    // Add the last guess to the archiveGuessesList with the correct/incorrect status
    const updatedarchiveGuessesList = [
      ...archiveGuessesList,
      { guess: selectedOption, isCorrect: isCorrectAnswer },
    ];
    setarchiveGuessesList(updatedarchiveGuessesList);
    localStorage.setItem(
      "archiveGuessesList",
      JSON.stringify(updatedarchiveGuessesList)
    );

    if (isCorrectAnswer) {
      setcorrectArchiveGuess(true);
      const randomCongratsMessage = {
        message: `${getRandomMessage()}`,
        status: "correct", // Add a status to indicate the guess was correct
      };
      setarchiveModalContent(randomCongratsMessage);
      localStorage.setItem("archiveModalContent", randomCongratsMessage);
      localStorage.setItem("correctArchiveGuess", "true");
      await updateMustacheStats(mustacheId, 4, true);
      setarchiveGuessesList(updatedarchiveGuessesList);
      setGameEnded(true);
      // You might want to handle game reset or navigation to a success screen here
    } else {
      setarchiveGameLost(true);
      const archiveGameLostMessage = {
        message: `Not this time!`,
        status: "incorrect", // Status for incorrect archiveGuesses
      };
      setarchiveModalContent(archiveGameLostMessage);
      localStorage.setItem(
        "archiveModalContent",
        JSON.stringify(archiveGameLostMessage)
      );
      localStorage.setItem("archiveGameLost", "true");
      await updateMustacheStats(mustacheId, 4, false);
      setarchiveGuessesList(updatedarchiveGuessesList);
      setGameEnded(true);
      // You might want to handle game reset or navigation to a failure screen here
    }

    // Increment archiveGuesses and clear the input
    const newarchiveGuesses = archiveGuesses + 1;
    setarchiveGuesses(newarchiveGuesses);
    localStorage.setItem("archiveGuesses", newarchiveGuesses.toString());
    setarchiveGuessValue(""); // Clear the input field after a guess

    // Since it's the last guess, consider resetting the game or navigating the user elsewhere
    // handleCleararchiveGuesses(true); // Optionally reset the game here if needed
  };

  const toggleAboutModal = () => setShowAboutModal(!showAboutModal);

  const getRandomMessage = () => {
    const randomIndex = Math.floor(
      Math.random() * congratulationsMessages.length
    );
    return congratulationsMessages[randomIndex];
  };

  const wrongarchiveGuesses = archiveGuessesList.filter(
    (guess) => !guess.isCorrect
  ).length;

  const toggleDarkMode = () => {
    const toggled = !isDarkMode;
    setIsDarkMode(toggled);
    localStorage.setItem("darkMode", JSON.stringify(toggled));
    document.body.classList.toggle("dark-mode", toggled);
  };

  const togglePrivacyModal = () => setShowPrivacyModal(!showPrivacyModal);

  return (
    <div className="App">
      <div className="top-row">
        <div className="top-row-left"></div>
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        </div>
        <div className="header-options">
          <Link to="/">
            <button className="archive-btn">DAILY</button>
          </Link>
          <button className="about-btn" onClick={toggleAboutModal}>
            <InfoIcon className="about-icon" />
          </button>
          {/* <div>
            <label>
              <div className="switch">
                <span className="sun">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <g fill="#ffd43b">
                      <circle r="5" cy="12" cx="12"></circle>
                      <path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path>
                    </g>
                  </svg>
                </span>
                <span className="moon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
                  </svg>
                </span>
                <input
                  id="dark-mode-toggle"
                  type="checkbox"
                  className="input"
                  checked={isDarkMode}
                  onChange={toggleDarkMode}
                  style={{ display: "none" }} // Ensure the checkbox is hidden but still functional
                />
                <span className="slider"></span>
              </div>
            </label>
          </div> */}
        </div>
      </div>

      <div className="archive-options-container">
        <div className="back-archive-container">
          <Link to="/archive">
            <button className="back-archive-btn">BACK TO ARCHIVE</button>
          </Link>
        </div>
      </div>
      <div className="game-title">ARCHIVE DAY#{day}</div>

      {/* {archiveScreenActive && (
        <div className="archive-main-screen-container">
          <div className="archive-title">ARCHIVE:</div>
          <div className="archive-btn-container">
            {Array.from({ length: currentMustacheIndex + 1 }, (_, index) => (
              <button
                className="archive-db-btn"
                key={index}
                onClick={() => handleMustacheSelect(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      )} */}

      {showAboutModal && (
        <InfoModal onClose={toggleAboutModal}>
          <h2>How to Play</h2>
          <p>
            Guess whose mustache is shown. <br></br>
            <br></br>
            Everyday at midnight (CST) there will be a new mustache to guess.
            <br></br> <br></br>
            You get 3 guesses, and then one multiple choice guess.
            <br></br>
            <br></br>
            After each guess it will zoom out a bit to help see who the person
            is.
            <br></br>
            <br></br>
            NOTE: These images are from wikipedia commons so I don't run into
            copyright issues. Therefore, they may not be the best/clearest
            images of the celebrities. Sorry about that. It is what it is for
            now.
          </p>
        </InfoModal>
      )}

      {showPrivacyModal && (
        <InfoModal onClose={togglePrivacyModal}>
          <PrivacyPolicyComponent></PrivacyPolicyComponent>
        </InfoModal>
      )}

      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : archiveCurrentMustache ? (
        <div className="message-box-image-container">
          {archiveModalContent && archiveModalContent.message && (
            <div
              className={`message-box ${
                archiveModalContent.status === "correct"
                  ? "correct"
                  : "incorrect"
              }`}
            >
              <p className="message">{archiveModalContent.message}</p>
              <div className="answer-container">
                <p className="answer">
                  It was {archiveCurrentMustache.answers[0]}!
                </p>
                <a
                  href={archiveCurrentMustache.bio}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="who-btn">WHO?</button>
                </a>
              </div>
              <StatsDisplay
                mustacheId={mustacheId}
                guessesList={archiveGuessesList}
                gameEnded={correctArchiveGuess || archiveGameLost}
              />
            </div>
          )}
          <DisplayImage
            src={
              correctArchiveGuess || archiveGameLost
                ? archiveCurrentMustache.fullSrc
                : archiveCurrentMustache.mustacheSrc
            }
            alt="Daily Mustache"
            sourceUrl={archiveCurrentMustache.source}
            license={archiveCurrentMustache.license}
            gameStatus={correctArchiveGuess || archiveGameLost}
            wrongarchiveGuesses={wrongarchiveGuesses}
          />
        </div>
      ) : (
        <p>Mustache data is not available.</p>
      )}

      {!correctArchiveGuess && !archiveGameLost && !archiveIsLastGuess && (
        <div
          className={`searchBox ${
            suggestions.length > 0 ? "show-rectangle" : ""
          }`}
        >
          <form onSubmit={handleGuess}>
            <input
              className="searchInput"
              type="text"
              placeholder="Guess"
              value={archiveInputValue}
              onChange={handleInputChange}
            />
            {renderSuggestions()}
            <button className="searchButton" type="submit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
              >
                <g clipPath="url(#clip0_2_17)">
                  <g filter="url(#filter0_d_2_17)">
                    <path
                      d="M23.7953 23.9182L19.0585 19.1814M19.0585 19.1814C19.8188 18.4211 20.4219 17.5185 20.8333 16.5251C21.2448 15.5318 21.4566 14.4671 21.4566 13.3919C21.4566 12.3167 21.2448 11.252 20.8333 10.2587C20.4219 9.2653 19.8188 8.36271 19.0585 7.60242C18.2982 6.84214 17.3956 6.23905 16.4022 5.82759C15.4089 5.41612 14.3442 5.20435 13.269 5.20435C12.1938 5.20435 11.1291 5.41612 10.1358 5.82759C9.1424 6.23905 8.23981 6.84214 7.47953 7.60242C5.94407 9.13789 5.08145 11.2204 5.08145 13.3919C5.08145 15.5634 5.94407 17.6459 7.47953 19.1814C9.01499 20.7168 11.0975 21.5794 13.269 21.5794C15.4405 21.5794 17.523 20.7168 19.0585 19.1814Z"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </g>
                </g>
                <defs>
                  <filter
                    id="filter0_d_2_17"
                    x="-0.418549"
                    y="3.70435"
                    width="29.7139"
                    height="29.7139"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood
                      floodOpacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    ></feColorMatrix>
                    <feOffset dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    ></feColorMatrix>
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_2_17"
                    ></feBlend>
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_2_17"
                      result="shape"
                    ></feBlend>
                  </filter>
                  <clipPath id="clip0_2_17">
                    <rect
                      width="28.0702"
                      height="28.0702"
                      fill="white"
                      transform="translate(0.403503 0.526367)"
                    ></rect>
                  </clipPath>
                </defs>
              </svg>
            </button>
          </form>
        </div>
      )}

      {archiveIsLastGuess && archiveCurrentMustache && (
        <div className="multiple-choice-container">
          {getMultipleChoiceOptions().map((option, index) => (
            <button
              key={index}
              className="multiple-choice-btn"
              onClick={() => handleGuessOption(option)}
            >
              {option}
            </button>
          ))}
        </div>
      )}

      {!correctArchiveGuess &&
        !archiveGameLost &&
        archiveGuesses < MAX_archiveGuesses && (
          <p className={`guesses-remaining ${isDarkMode ? "dark" : ""}`}>
            {MAX_archiveGuesses - archiveGuesses} GUESS
            {MAX_archiveGuesses - archiveGuesses === 1 ? "" : "ES"} REMAINING
          </p>
        )}

      {archiveGuesses > 0 && <div className="guesses-list-title">GUESSES:</div>}
      <div className="guesses-list">
        {archiveGuessesList.map((item, index) => (
          <div key={index}>
            {item.guess}{" "}
            <span className={item.isCorrect ? "guess-correct" : "guess-wrong"}>
              {item.isCorrect ? "✓" : "x"}
            </span>
          </div>
        ))}
      </div>

      <div className="footer-container">
        <footer className="app-footer">
          <span className="email">Email: Logan@guessthestache.com</span>
          <button className="privacy-policy" onClick={togglePrivacyModal}>
            Privacy Policy
          </button>
        </footer>
      </div>
    </div>
  );
}

export default ArchiveGamePage;
