export const answers = [
  {
    answers: ["Dr. Phil", "Phillip McGraw", "Dr Phil", "DrPhil", "phil mcgraw"],
    mustacheSrc: "./assets/mustaches/mustache(0).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/7/75/Dr.Phil2013.jpg",
    source: "https://commons.wikimedia.org/wiki/File:Dr.Phil2013.jpg",
    license:
      "Angela George, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    multipleChoice: ["Clint Eastwood", "Bruce Willis", "John Travolta"],
    bio: "https://en.wikipedia.org/wiki/Phil_McGraw",
  },
  {
    answers: [
      "Nick Offerman",
      "Ron Swanson",
      "Nicholas Offerman",
      "Ronald Swanson",
      "Swanson",
      "Offerman",
      "The guy from parks and rec",
    ],
    mustacheSrc: "./assets/mustaches/mustache(1).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Nick_Offerman_2013_Headshot_%28cropped%29.jpg/768px-Nick_Offerman_2013_Headshot_%28cropped%29.jpg?20140321145404",
    license:
      "UMBC Student Events Board, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source:
      "https://commons.wikimedia.org/wiki/File:Nick_Offerman_2013_Headshot_(cropped).jpg",
    multipleChoice: ["Ed Helms", "Zach Galifianakis", "Russell Crowe"],
    bio: "https://en.wikipedia.org/wiki/Nick_Offerman",
  },
  {
    answers: ["Steve Harvey", "Stephen Harvey", "Harvey"],
    mustacheSrc: "./assets/mustaches/mustache(2).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/1/16/SteveHarveyHWOFMay2013_%28cropped%29.jpg",
    license:
      "Angela George, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source:
      "https://commons.wikimedia.org/wiki/File:SteveHarveyHWOFMay2013_(cropped).jpg",
    multipleChoice: ["Denzel Washington", "Richard Pryor", "Lavar Ball"],
  },
  {
    answers: [
      "Leslie David Baker",
      "Stanley",
      "Stanley Hudson",
      "Stanley from the office",
      "Stanley the office",
    ],
    mustacheSrc: "./assets/mustaches/mustache(3).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/5/53/Leslie_David_Baker_Photo_Op_GalaxyCon_Raleigh_2019.jpg",
    license:
      "Super Festivals from Ft. Lauderdale, USA, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source:
      "https://commons.wikimedia.org/wiki/File:Leslie_David_Baker_Photo_Op_GalaxyCon_Raleigh_2019.jpg",
    multipleChoice: ["Eddie Murphy", "Forest Whitaker", "Bernie Mac"],
    bio: "https://en.wikipedia.org/wiki/Leslie_David_Baker",
  },
  {
    answers: [
      "Albert Einstein",
      "Einstein",
      "Al Einstein",
      "Einstien",
      "Albert Einstien",
      "Big albert",
      "Smart al",
      "big al",
    ],
    mustacheSrc: "./assets/mustaches/mustache(4).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/d/d3/Albert_Einstein_Head.jpg",
    license:
      "Photograph by Orren Jack Turner, Princeton, N.J. Modified with Photoshop by PM_Poon and later by Dantadd., Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Albert_Einstein_Head.jpg",
    multipleChoice: ["Friedrich Nietzsche", "Theodore Roosevelt", "Mark Twain"],
    bio: "https://en.wikipedia.org/wiki/Albert_Einstein",
  },
  {
    answers: [
      "Martin Luther King Jr",
      "Martin Luther King Jr.",
      "Martin luther king",
      "dr king",
      "dr. king",
      "king",
      "luther",
      "mlk",
      "mlk jr",
      "mlkjr",
    ],
    mustacheSrc: "./assets/mustaches/mustache(5).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/05/Martin_Luther_King%2C_Jr..jpg",
    license: "Nobel Foundation, Public domain, via Wikimedia Commons",
    source:
      "https://commons.wikimedia.org/wiki/File:Martin_Luther_King,_Jr..jpg",
    multipleChoice: ["Barack Obama", "Nelson Mandela", "Malcom X"],
    bio: "https://en.wikipedia.org/wiki/Martin_Luther_King_Jr.",
  },
  {
    answers: [
      "Eddie Murphy",
      "Ed Murphy",
      "Murphy",
      "eddiemurphy",
      "edward murphy",
      "eddy murphy",
    ],
    mustacheSrc: "./assets/mustaches/mustache(6).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/5/5f/Eddie_Murphy_by_David_Shankbone.jpg",
    license: "David Shankbone, PDM-owner, via Wikimedia Commons",
    source:
      "https://commons.wikimedia.org/wiki/File:Eddie_Murphy_by_David_Shankbone.jpg",
    multipleChoice: ["Richard Pryor", "Chris Rock", "Ice Cube"],
    bio: "https://en.wikipedia.org/wiki/Eddie_Murphy",
  },
  {
    answers: [
      "Colin Farrell",
      "Collin Farrell",
      "Colin Farell",
      "Colin Farel",
      "Colin Farrel",
      "Farrell",
      "Colin Frell",
    ],
    mustacheSrc: "./assets/mustaches/mustache(7).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/8/8b/Colin_Farrell_by_David_Shankbone.jpg",
    license:
      "David Shankbone, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source:
      "https://commons.wikimedia.org/wiki/File:Colin_Farrell_by_David_Shankbone.jpg",
    multipleChoice: ["Bradley Cooper", "John Krasinski", "Al Pacino"],
    bio: "https://en.wikipedia.org/wiki/Colin_Farrell",
  },
  {
    answers: ["Brad Pitt", "Bradley Pitt", "Brad Pit", "Angelina's husband"],
    mustacheSrc: "./assets/mustaches/mustache(8).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/9/98/Brad_Pitt_in_2014_%28cropped%29.jpg",
    license:
      "DoD News Features, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source:
      "https://commons.wikimedia.org/wiki/File:Brad_Pitt_in_2014_(cropped).jpg",
    multipleChoice: ["Tom Cruise", "Owen Wilson", "Charlie Hunnam"],
    bio: "https://en.wikipedia.org/wiki/Brad_Pitt",
  },
  {
    answers: [
      "Johnny Depp",
      "John Depp",
      "Johnny Dep",
      "Jonny Depp",
      "Johnnie Depp",
      "Depp",
    ],
    mustacheSrc: "./assets/mustaches/mustache(9).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/6/64/Johnny_Depp_2%2C_2011.jpg",
    license:
      "Arnold Wells, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Johnny_Depp_2,_2011.jpg",
    multipleChoice: ["Jared Leto", "Keanu Reeves", "Robert Downey Jr"],
    bio: "https://en.wikipedia.org/wiki/Johnny_Depp",
  },
  {
    answers: [
      "Michael B Jordan",
      "Michael B. Jordan",
      "Mike B Jordan",
      "B Jordan",
    ],
    mustacheSrc: "./assets/mustaches/mustache(10).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/c/cd/2018-05-12-_Cannes-L%27acteur_Michael_B._Jordan-2721_%2842075892224%29.jpg",
    license:
      "Joan Hernandez Mir, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source:
      "https://commons.wikimedia.org/wiki/File:2018-05-12-_Cannes-L%27acteur_Michael_B._Jordan-2721_(42075892224).jpg",
    multipleChoice: ["Chris Brown", "Nick Cannon", "Jonathan Majors"],
    bio: "https://en.wikipedia.org/wiki/Michael_B._Jordan",
  },
  {
    answers: [
      "Jason Sudeikis",
      "Ted Lasso",
      "Sudeikis",
      "Jason Sadaykiss",
      "Jason Sudiekis",
      "Jason Sudeikiss",
    ],
    mustacheSrc: "./assets/mustaches/mustache(11).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/6/6d/Jason_Sudeikis_on_March_20%2C_2023_in_the_Oval_Office_of_the_White_House_-_P20230320AS-2571_%28cropped%29.jpg",
    license: "The White House, Public domain, via Wikimedia Commons",
    source:
      "https://commons.wikimedia.org/wiki/File:Jason_Sudeikis_on_March_20,_2023_in_the_Oval_Office_of_the_White_House_-_P20230320AS-2571_(cropped).jpg",
    multipleChoice: ["Paul Rudd", "Ed Helms", "John Krasinski"],
    bio: "https://en.wikipedia.org/wiki/Jason_Sudeikis",
  },
  {
    answers: ["Lionel Richie", "Lionel Ritchie"],
    mustacheSrc: "./assets/mustaches/mustache(12).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/9/9f/Lionel_Richie_1984.jpg",
    license:
      "Penni Gladstone, Los Angeles Times, CC BY 4.0 <https://creativecommons.org/licenses/by/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Lionel_Richie_1984.jpg",
    multipleChoice: ["Danny Green", "Freddie Mercury", "Richard Pryor"],
    bio: "https://en.wikipedia.org/wiki/Lionel_Richie",
  },
  {
    answers: ["Christian Bale", "Christian Bail", "Cristian Bale"],
    mustacheSrc: "./assets/mustaches/mustache(13).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/c/c2/Christian_Bale-7838.jpg",
    license:
      "Harald Krichel, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Christian_Bale-7838.jpg",
    multipleChoice: ["Hugh Jackman", "Jake Gyllenhaal", "Tom Cruise"],
    bio: "https://en.wikipedia.org/wiki/Christian_Bale",
  },
  {
    answers: ["Theodore Roosevelt", "Teddy Roosevelt", "Roosevelt"],
    mustacheSrc: "./assets/mustaches/mustache(14).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/5/5b/Theodore_Roosevelt_by_the_Pach_Bros.jpg",
    license:
      "https://commons.wikimedia.org/wiki/File:Theodore_Roosevelt_by_the_Pach_Bros.jpg",
    source: "https://commons.wikimedia.org/wiki/File:Theodore_Roosevelt_by_the_Pach_Bros.jpg",
    multipleChoice: ["William Howard Taft", "Friedrich Nietzsche", "Albert Einstein"],
    bio: "https://en.wikipedia.org/wiki/Theodore_Roosevelt",
  },
  {
    answers: ["Burt Reynolds"],
    mustacheSrc: "./assets/mustaches/mustache(15).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/4/4c/Red_Bandit_Jacket.jpg",
    license:
      "Watkinssportswear, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Red_Bandit_Jacket.jpg",
    multipleChoice: ["Sean Connery", "Tom Selleck", "Pedro Pascal"],
    bio: "https://en.wikipedia.org/wiki/Burt_Reynolds",
  },
  {
    answers: ["Jack Nicholson"],
    mustacheSrc: "./assets/mustaches/mustache(16).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/c/ca/Jack_Nicholson_1971.jpg",
    license:
      "Borsari, Peter, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jack_Nicholson_1971.jpg",
    multipleChoice: ["Burt Reynolds", "Tom Cruise", "Jack Lemmon"],
    bio: "https://en.wikipedia.org/wiki/Jack_Nicholson",
  },
  {
    answers: ["Conor McGregor", "McGregor", "Connor McGregor"],
    mustacheSrc: "./assets/mustaches/mustache(17).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/f/fd/McGregor_London_2015.jpg",
    license:
      "Andrius Petrucenia, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:McGregor_London_2015.jpg",
    multipleChoice: ["Charlie Hunnam", "Chris Hemsworth", "Ryan Gosling"],
    bio: "https://en.wikipedia.org/wiki/Conor_McGregor",
  },
  {
    answers: ["Robin Williams", "Robin"],
    mustacheSrc: "./assets/mustaches/mustache(18).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/7/74/Robin_Williams_2010.jpg",
    license:
      "Chairman of the Joint Chiefs of Staff from Washington D.C, United States, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Robin_Williams_2010.jpg",
    multipleChoice: ["Andy Reid", "Clint Eastwood", "Jeff Bridges"],
    bio: "https://en.wikipedia.org/wiki/Robin_Williams",
  },
  {
    answers: ["Ethan Hawke", "Hawke"],
    mustacheSrc: "./assets/mustaches/mustache(19).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/d/d9/Ethan_Hawke_66%C3%A8me_Festival_de_Venise_2.jpg",
    license:
      "Nicolas Genin, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Ethan_Hawke_66%C3%A8me_Festival_de_Venise_2.jpg",
    multipleChoice: ["Colin Farrell", "Brad Pitt", "Kevin Bacon"],
    bio: "https://en.wikipedia.org/wiki/Ethan_Hawke",
  },
  {
    answers: ["Leonardo DiCaprio", "DiCaprio", "Leo", "Leo DeCaps", "DeCaps"],
    mustacheSrc: "./assets/mustaches/mustache(20).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/3f/Leonardo_DiCaprio_visited_Goddard_Saturday_to_discuss_Earth_science_with_Piers_Sellers_%2826105091624%29_cropped.jpg",
    license:
      "NASA/Goddard/Rebecca Roth, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Leonardo_DiCaprio_visited_Goddard_Saturday_to_discuss_Earth_science_with_Piers_Sellers_(26105091624)_cropped.jpg",
    multipleChoice: ["Brad Pitt", "Matt Damon", "Chad Michael Murray"],
    bio: "https://en.wikipedia.org/wiki/Leonardo_DiCaprio",
  },
  {
    answers: ["Chris Pratt"],
    mustacheSrc: "./assets/mustaches/mustache(21).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/c/c2/Chris_Pratt_by_Gage_Skidmore_2.jpg",
    license:
      "Gage Skidmore, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Chris_Pratt_by_Gage_Skidmore_2.jpg",
    multipleChoice: ["Jason Segel", "Chris Evans", "John Krasinski"],
    bio: "https://en.wikipedia.org/wiki/Chris_Pratt",
  },
  {
    answers: ["Al Pacino"],
    mustacheSrc: "./assets/mustaches/mustache(22).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/9/98/Al_Pacino.jpg",
    license:
      "Kindly granted by Thomas Schulz from Vienna, Austria, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Al_Pacino.jpg",
    multipleChoice: ["Johnny Depp", "Steven Seagal", "Robert De Niro"],
    bio: "https://en.wikipedia.org/wiki/Al_Pacino",
  },
  {
    answers: ["Jamie Foxx"],
    mustacheSrc: "./assets/mustaches/mustache(23).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/e/ed/Jamie_Foxx_Django_avp.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jamie_Foxx_Django_avp.jpg",
    multipleChoice: ["Denzel Washington", "Michael Strahan", "Don Cheadle"],
    bio: "https://en.wikipedia.org/wiki/Jamie_Foxx",
  },
  {
    answers: ["Jack Black"],
    mustacheSrc: "./assets/mustaches/mustache(24).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/2/24/Jack_Black-004_%2822368658392%29.jpg",
    license:
      "Renan Katayama, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jack_Black-004_(22368658392).jpg",
    multipleChoice: ["Kevin James", "Josh Gad", "Zach Galifianakis"],
    bio: "https://en.wikipedia.org/wiki/Jack_Black",
  },
  {
    answers: ["Sam Elliot"],
    mustacheSrc: "./assets/mustaches/mustache(25).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/a1/TIFF_2018_Samuel_Elliott_%2844592132581%29_%28cropped%29.jpg",
    license:
      "John Bauld from Toronto, Canada, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:TIFF_2018_Samuel_Elliott_(44592132581)_(cropped).jpg",
    multipleChoice: ["Mark Twain", "Dr Phil", "George Clooney"],
    bio: "https://en.wikipedia.org/wiki/Sam_Elliott",
  },
  {
    answers: ["Post Malone"],
    mustacheSrc: "./assets/mustaches/mustache(26).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/9/94/Post_Malone_2018.jpg",
    license:
      "Toglenn, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Post_Malone_2018.jpg",
    multipleChoice: ["Shia LaBeouf", "Morgan Wallen", "Jonah Hill"],
    bio: "https://en.wikipedia.org/wiki/Post_Malone",
  },
  {
    answers: ["Paul Giamatti"],
    mustacheSrc: "./assets/mustaches/mustache(27).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/37/Paul_Giamatti_2024.jpg",
    license:
      "Raph_PH, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Paul_Giamatti_2024.jpg",
    multipleChoice: ["Dr Phil", "Andy Reid", "Jack Black"],
    bio: "https://en.wikipedia.org/wiki/Paul_Giamatti",
  },
  {
    answers: ["Hulk Hogan"],
    mustacheSrc: "./assets/mustaches/mustache(28).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/0e/Hulk_Hogan.jpg",
    license:
      "Petty Officer 1st Class Kristin Fitzsimmons, USN, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Hulk_Hogan.jpg",
    multipleChoice: ["Anthony Hopkins", "David Letterman", "Sam Elliot"],
    bio: "https://en.wikipedia.org/wiki/Hulk_Hogan",
  },
  {
    answers: ["Ryan Gosling"],
    mustacheSrc: "./assets/mustaches/mustache(29).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/2/2b/Ryan_Gosling_%2816437257783%29_%28cropped%29.jpg",
    license:
      "Elen Nivrae from Paris, France, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Ryan_Gosling_(16437257783)_(cropped).jpg",
    multipleChoice: ["Ryan Reynolds", "Brad Pitt", "Charlie Hunnam"],
    bio: "https://en.wikipedia.org/wiki/Ryan_Gosling",
  },
  {
    answers: ["Steve Buscemi"],
    mustacheSrc: "./assets/mustaches/mustache(30).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/9/90/Steve_Buscemi_2009_portrait.jpg",
    license:
      "David Shankbone, CC BY 3.0 <https://creativecommons.org/licenses/by/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Steve_Buscemi_2009_portrait.jpg",
    multipleChoice: ["Michael Cera", "Willem Dafoe", "Macaulay Culkin"],
    bio: "https://en.wikipedia.org/wiki/Steve_Buscemi",
  },
  {
    answers: ["Jeff Bridges"],
    mustacheSrc: "./assets/mustaches/mustache(31).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/a1/Jeff_Bridges_by_Gage_Skidmore_3.jpg",
    license:
      "Gage Skidmore, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jeff_Bridges_by_Gage_Skidmore_3.jpg",
    multipleChoice: ["Kurt Russell", "Willem Dafoe", "Josh Brolin"],
    bio: "https://en.wikipedia.org/wiki/Jeff_Bridges",
  },
  {
    answers: ["Aaron Rodgers"],
    mustacheSrc: "./assets/mustaches/mustache(32).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/2/2e/Aaron_Rodgers_Packers_OCT2021_%28cropped%29.jpg",
    license:
      "All-Pro Reels, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Aaron_Rodgers_Packers_OCT2021_(cropped).jpg",
    multipleChoice: ["Alex Smith", "Gardner Minshew", "Jared Leto"],
    bio: "https://en.wikipedia.org/wiki/Aaron_Rodgers",
  },
  {
    answers: ["Robert Downey Jr.", "RDJ"],
    mustacheSrc: "./assets/mustaches/mustache(33).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/0a/Robert_Downey_Jr_avp_Iron_Man_3_Paris.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Robert_Downey_Jr_avp_Iron_Man_3_Paris.jpg",
    multipleChoice: ["Javier Bardem", "Johnny Depp", "Quentin Tarantino"],
    bio: "https://en.wikipedia.org/wiki/Robert_Downey_Jr.",
  },
  {
    answers: ["Pedro Pascal"],
    mustacheSrc: "./assets/mustaches/mustache(34).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/8/87/Pedro_Pascal_SDCC_2014.jpg",
    license:
      "Gage Skidmore, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Pedro_Pascal_SDCC_2014.jpg",
    multipleChoice: ["Chris Pratt", "Oscar Isaac", "Paul Mescal"],
    bio: "https://en.wikipedia.org/wiki/Pedro_Pascal",
  },
  {
    answers: ["Andy Reid"],
    mustacheSrc: "./assets/mustaches/mustache(35).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/b/b9/Andy_reid_2018_%28cropped%29.jpg",
    license:
      "U.S. Air National Guard Master Sgt. Michael Crane, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Andy_reid_2018_(cropped).jpg",
    multipleChoice: ["Dr Phil", "Eric Stonestreet", "Kevin James"],
    bio: "https://en.wikipedia.org/wiki/Andy_Reid",
  },
  {
    answers: ["Mike Tyson"],
    mustacheSrc: "./assets/mustaches/mustache(36).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/e/e1/Mike_Tyson_2019_by_Glenn_Francis.jpg",
    license:
      "Toglenn, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Mike_Tyson_2019_by_Glenn_Francis.jpg",
    multipleChoice: ["Bill Russell", "Morgan Freeman", "Jamie Foxx"],
    bio: "https://en.wikipedia.org/wiki/Mike_Tyson",
  },
  {
    answers: ["Gardner Minshew"],
    mustacheSrc: "./assets/mustaches/mustache(37).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/9/9d/Gardner_Minshew_%28cropped%29.jpg",
    license:
      "All-Pro Reels, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Gardner_Minshew_(cropped).jpg",
    multipleChoice: ["Andrew Luck", "Baker Mayfield", "Aaron Rodgers"],
    bio: "https://en.wikipedia.org/wiki/Gardner_Minshew",
  },
  {
    answers: ["Kurt Russell"],
    mustacheSrc: "./assets/mustaches/mustache(38).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/1/1a/Kurt_Russell_by_Gage_Skidmore.jpg",
    license:
      "Gage Skidmore, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Kurt_Russell_by_Gage_Skidmore.jpg",
    multipleChoice: ["Josh Brolin", "Jeff Bridges", "Dan Campbell"],
    bio: "https://en.wikipedia.org/wiki/Kurt_Russell",
  },
  {
    answers: ["Tom Hanks"],
    mustacheSrc: "./assets/mustaches/mustache(39).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/a9/Tom_Hanks_TIFF_2019.jpg",
    license:
      "John Bauld from Toronto, Canada, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Tom_Hanks_TIFF_2019.jpg",
    multipleChoice: ["Nick Nolte", "Anthony Hopkins", "Sean Connery"],
    bio: "https://en.wikipedia.org/wiki/Tom_Hanks",
  },
  {
    answers: ["Sean Penn"],
    mustacheSrc: "./assets/mustaches/mustache(40).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/8/88/Seanpenn1.jpg",
    license:
      "seher sikandar, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Seanpenn1.jpg",
    multipleChoice: ["Patrick Dempsey", "John Hawkes", "Matthew Perry"],
    bio: "https://en.wikipedia.org/wiki/Sean_Penn",
  },
  {
    answers: ["David Letterman", "Dave Letterman"],
    mustacheSrc: "./assets/mustaches/mustache(41).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/d/de/David_letterman_%2847966353856%29.jpg",
    license:
      "Zach Catanzareti Photo, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:David_letterman_(47966353856).jpg",
    multipleChoice: ["Anthony Hopkins", "Donald Sutherland", "J.K. Simmons"],
    bio: "https://en.wikipedia.org/wiki/David_Letterman",
  },
  {
    answers: ["Justin Timberlake"],
    mustacheSrc: "./assets/mustaches/mustache(42).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/7/72/Justin_Timberlake_by_Gage_Skidmore.jpg",
    license:
      "Gage Skidmore, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Justin_Timberlake_by_Gage_Skidmore.jpg",
    multipleChoice: ["Jake Johnson", "Chris Pratt", "Jimmy Fallon"],
    bio: "https://en.wikipedia.org/wiki/Justin_Timberlake",
  },
  {
    answers: ["Jake Gyllenhaal"],
    mustacheSrc: "./assets/mustaches/mustache(43).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/0e/Jake_Gyllenhaal_2019_by_Glenn_Francis.jpg",
    license:
      "Toglenn, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jake_Gyllenhaal_2019_by_Glenn_Francis.jpg",
    multipleChoice: ["Tobey Maguire", "Jared Leto", "Christian Bale"],
    bio: "https://en.wikipedia.org/wiki/Jake_Gyllenhaal",
  },
  {
    answers: ["George Clooney"],
    mustacheSrc: "./assets/mustaches/mustache(44).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/f/f8/George_Clooney_KCH_2022.jpg",
    license:
      "U.S. Department of State from United States, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:George_Clooney_KCH_2022.jpg",
    multipleChoice: ["Patrick Dempsey", "J.K. Simmons", "Sean Connery"],
    bio: "https://en.wikipedia.org/wiki/George_Clooney",
  },
  {
    answers: ["Jake Johnson"],
    mustacheSrc: "./assets/mustaches/mustache(45).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/0f/Jake_Johnson_by_Gage_Skidmore.jpg",
    license:
      "Gage Skidmore, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jake_Johnson_by_Gage_Skidmore.jpg",
    multipleChoice: ["Christian Bale", "Oscar Isaac", "Jake Gyllenhaal"],
    bio: "https://en.wikipedia.org/wiki/Jake_Johnson",
  },
  {
    answers: ["Tom Selleck"],
    mustacheSrc: "./assets/mustaches/mustache(46).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/1/18/Tom_Selleck_at_PaleyFest_2014.jpg",
    license:
      "Dominick D, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Tom_Selleck_at_PaleyFest_2014.jpg",
    multipleChoice: ["Burt Reynolds", "Nick Offerman", "Don Frye"],
    bio: "https://en.wikipedia.org/wiki/Tom_Selleck",
  },
  {
    answers: ["Richard Pryor"],
    mustacheSrc: "./assets/mustaches/mustache(47).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/b/bb/Richard_Pryor_%281986%29_%28cropped%29.jpg",
    license:
      "photo by Alan Light, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Richard_Pryor_(1986)_(cropped).jpg",
    multipleChoice: ["Eddie Murphy", "Stevie Wonder", "Lionel Richie"],
    bio: "https://en.wikipedia.org/wiki/Richard_Pryor",
  },
  {
    answers: ["Keanu Reeves"],
    mustacheSrc: "./assets/mustaches/mustache(48).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/6/67/Keanu_Reeves-2019.jpg",
    license:
      "Governo do Estado de São Paulo, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Keanu_Reeves-2019.jpg",
    multipleChoice: ["Adam Driver", "Tom Cruise", "Jake Gyllenhaal"],
    bio: "https://en.wikipedia.org/wiki/Keanu_Reeves",
  },
  {
    answers: ["Chris Hemsworth"],
    mustacheSrc: "./assets/mustaches/mustache(49).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/4/4c/Chris_Hemsworth_by_Gage_Skidmore.jpg",
    license:
      "Gage Skidmore, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Chris_Hemsworth_by_Gage_Skidmore.jpg",
    multipleChoice: ["Chris Pratt", "Chris Pine", "Chris Evans"],
    bio: "https://en.wikipedia.org/wiki/Chris_Hemsworth",
  },
  {
    answers: ["Idris Elba"],
    mustacheSrc: "./assets/mustaches/mustache(50).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/0e/Idris_Elba-4580_%28cropped%29.jpg",
    license:
      "Harald Krichel, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Idris_Elba-4580_(cropped).jpg",
    multipleChoice: ["Dwyane Wade", "Denzel Washington", "Mike Tyson"],
    bio: "https://en.wikipedia.org/wiki/Idris_Elba",
  },
  {
    answers: ["Jeff Foxworthy"],
    mustacheSrc: "./assets/mustaches/mustache(51).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/b/b6/Jeff_Foxworthy.jpg",
    license:
      "Kingkongphoto & www.celebrity-photos.com from Laurel  Maryland, USA, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jeff_Foxworthy.jpg",
    multipleChoice: ["Steve Buscemi", "Bradley Cooper", "Dr. Phil"],
    bio: "https://en.wikipedia.org/wiki/Jeff_Foxworthy",
  },
  {
    answers: ["Ben Affleck"],
    mustacheSrc: "./assets/mustaches/mustache(52).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/7/70/Ben_Affleck_by_Gage_Skidmore_3.jpg",
    license:
      "Gage Skidmore, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Ben_Affleck_by_Gage_Skidmore_3.jpg",
    multipleChoice: ["Zachary Levi", "Ryan Reynolds", "Matt Damon"],
    bio: "https://en.wikipedia.org/wiki/Ben_Affleck",
  },
  {
    answers: ["Dennis Farina"],
    mustacheSrc: "./assets/mustaches/mustache(53).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/7/75/Dennis_Farina.jpg",
    license:
      "Kingkongphoto & www.celebrity-photos.com from Laurel  Maryland, USA, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Dennis_Farina.jpg",
    multipleChoice: ["Alex Trebek", "David Letterman", "Burt Reynolds"],
    bio: "https://en.wikipedia.org/wiki/Dennis_Farina",
  },
  {
    answers: ["Jason Mamoa"],
    mustacheSrc: "./assets/mustaches/mustache(54).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/4/45/Jason_Momoa_July_2014.jpg",
    license:
      "Florida Supercon, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jason_Momoa_July_2014.jpg",
    multipleChoice: ["Aaron Rodgers", "Keanu Reeves", "Steven Adams"],
    bio: "https://en.wikipedia.org/wiki/Jason_Momoa",
  },
  {
    answers: ["Adam Sandler"],
    mustacheSrc: "./assets/mustaches/mustache(55).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/39/Adam_Sandler_at_Berlinale_2024.jpg",
    license:
      "Elena Ternovaja, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Adam_Sandler_at_Berlinale_2024.jpg",
    multipleChoice: ["Zachary Levi", "Kevin James", "Ben Stiller"],
    bio: "https://en.wikipedia.org/wiki/Adam_Sandler",
  },
  {
    answers: ["Travis Kelce"],
    mustacheSrc: "./assets/mustaches/mustache(56).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/f/f5/Travis_Kelce_in_the_Oval_Office_of_the_White_House_on_June_5%2C_2023_-_P20230605AS-0902_%28cropped%29.jpg",
    license:
      "Adam Schultz, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Travis_Kelce_in_the_Oval_Office_of_the_White_House_on_June_5,_2023_-_P20230605AS-0902_(cropped).jpg",
    multipleChoice: ["Justin Timberlake", "Chris Pratt", "Jonas Valančiūnas"],
    bio: "https://en.wikipedia.org/wiki/Travis_Kelce",
  },
  {
    answers: ["Tom Hardy"],
    mustacheSrc: "./assets/mustaches/mustache(57).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/4/43/Tom_Hardy_by_Gage_Skidmore.jpg",
    license:
      "Gage Skidmore, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Tom_Hardy_by_Gage_Skidmore.jpg",
    multipleChoice: ["Ryan Gosling", "Ryan Reynolds", "Russell Crowe"],
    bio: "https://en.wikipedia.org/wiki/Tom_Hardy",
  },
  {
    answers: ["Jamie Hyneman"],
    mustacheSrc: "./assets/mustaches/mustache(58).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/a2/Jamie_Hyneman_in_2016.jpg",
    license:
      "Okras, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jamie_Hyneman_in_2016.jpg",
    multipleChoice: ["Brad Pitt", "Charlie Hunnam", "Matt Damon"],
    bio: "https://en.wikipedia.org/wiki/Jamie_Hyneman",
  },
  {
    answers: ["Carl Weathers"],
    mustacheSrc: "./assets/mustaches/mustache(59).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/7/72/Carl_Weathers_%28cropped_3_by_4%29.jpg",
    license:
      "Luigi Novi, CC BY 4.0 <https://creativecommons.org/licenses/by/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Carl_Weathers_(cropped_3_by_4).jpg",
    multipleChoice: ["Jamie Foxx", "Will Smith", "Eddie Murphy"],
    bio: "https://en.wikipedia.org/wiki/Carl_Weathers",
  },
  {
    answers: ["Shia LaBeouf"],
    mustacheSrc: "./assets/mustaches/mustache(60).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/6/6b/Shia_LaBeouf_Cannes_2012.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Shia_LaBeouf_Cannes_2012.jpg",
    multipleChoice: ["Keanu Reeves", "Bradley Cooper", "Jared Leto"],
    bio: "https://en.wikipedia.org/wiki/Shia_LaBeouf",
  },
  {
    answers: ["Ryan Reynolds"],
    mustacheSrc: "./assets/mustaches/mustache(61).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/1/14/Deadpool_2_Japan_Premiere_Red_Carpet_Ryan_Reynolds_%28cropped%29.jpg",
    license:
      "Dick Thomas Johnson from Tokyo, Japan, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Deadpool_2_Japan_Premiere_Red_Carpet_Ryan_Reynolds_(cropped).jpg",
    multipleChoice: ["Chris Pratt", "Ryan Gosling", "Jake Gyllenhaal"],
    bio: "https://en.wikipedia.org/wiki/Ryan_Reynolds",
  },
  {
    answers: ["LeBron James"],
    mustacheSrc: "./assets/mustaches/mustache(62).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/7/7a/LeBron_James_%2851959977144%29_%28cropped2%29.jpg",
    license:
      "Erik Drost, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:LeBron_James_(51959977144)_(cropped2).jpg",
    multipleChoice: ["James Harden", "Anthony Davis", "Carlos Boozer"],
    bio: "https://en.wikipedia.org/wiki/LeBron_James",
  },
  {
    answers: ["Walt Disney"],
    mustacheSrc: "./assets/mustaches/mustache(63).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/d/df/Walt_Disney_1946.JPG",
    license:
      "Boy Scouts of America, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Walt_Disney_1946.JPG",
    multipleChoice: ["Paul Newman", "Burt Reynolds", "Charlie Chaplin"],
    bio: "https://en.wikipedia.org/wiki/Walt_Disney",
  },
  {
    answers: ["Matthew McConaughey"],
    mustacheSrc: "./assets/mustaches/mustache(64).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/2/26/Matthew_McConaughey_Cannes_2015_2.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Matthew_McConaughey_Cannes_2015_2.jpg",
    multipleChoice: ["Colin Farrell", "Bradley Cooper", "Jason Bateman"],
    bio: "https://en.wikipedia.org/wiki/Matthew_McConaughey",
  },
  {
    answers: ["Daniel Kaluuya"],
    mustacheSrc: "./assets/mustaches/mustache(65).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/6/6a/Daniel_Kaluuya_%2835411578144%29_%28cropped_2%29.jpg",
    license:
      "Gage Skidmore from Peoria, AZ, United States of America, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Daniel_Kaluuya_(35411578144)_(cropped_2).jpg",
    multipleChoice: ["Lamorne Morris", "Leslie Odom Jr.", "LaKeith Stanfield"],
    bio: "https://en.wikipedia.org/wiki/Daniel_Kaluuya",
  },
  {
    answers: ["Joe Manganiello"],
    mustacheSrc: "./assets/mustaches/mustache(66).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/e/e5/Joe_Manganiello_July_2015.jpg",
    license:
      "Eva Rinaldi, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Joe_Manganiello_July_2015.jpg",
    multipleChoice: ["Liam Neeson", "Tony Gonzalez", "Vince Vaughn"],
    bio: "https://en.wikipedia.org/wiki/Joe_Manganiello",
  },
  {
    answers: ["David Spade"],
    mustacheSrc: "./assets/mustaches/mustache(67).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/4/40/David_Spade2_%28cropped%29.jpg",
    license:
      "dodge challenger1, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:David_Spade2_(cropped).jpg",
    multipleChoice: ["Danny McBride", "Owen Wilson", "Dana Carvey"],
    bio: "https://en.wikipedia.org/wiki/David_Spade",
  },
  {
    answers: ["Benicio Del Toro"],
    mustacheSrc: "./assets/mustaches/mustache(68).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/32/Benicio_Del_Toro_-_Guardians_of_the_Galaxy_premiere_-_July_2014_%28cropped%29.jpg",
    license:
      "Mingle Media TV, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Benicio_Del_Toro_-_Guardians_of_the_Galaxy_premiere_-_July_2014_(cropped).jpg",
    multipleChoice: ["Brad Pitt", "Tony Gonzalez", "Javier Bardem"],
    bio: "https://en.wikipedia.org/wiki/Benicio_del_Toro",
  },
  {
    answers: ["Shaquille O'Neal", "Shaq"],
    mustacheSrc: "./assets/mustaches/mustache(69).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/f/fd/Shaquille_O%27Neal_1998.jpg",
    license:
      "John Mathew Smith & www.celebrity-photos.com from Laurel  Maryland, USA, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Shaquille_O%27Neal_1998.jpg",
    multipleChoice: ["Gary Payton", "Kobe Bryant", "Hakeem Olajuwon"],
    bio: "https://en.wikipedia.org/wiki/Shaquille_O%27Neal",
  },
  {
    answers: ["Adam Driver"],
    mustacheSrc: "./assets/mustaches/mustache(70).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/4/4d/Star_Wars-_The_Last_Jedi_Japan_Premiere_Red_Carpet-_Adam_Driver_%2827163437599%29_%28cropped%29.jpg",
    license:
      "Dick Thomas Johnson from Tokyo, Japan, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Star_Wars-_The_Last_Jedi_Japan_Premiere_Red_Carpet-_Adam_Driver_(27163437599)_(cropped).jpg",
    multipleChoice: ["Shia LaBeouf", "Keanu Reeves", "Jerry Trainor"],
    bio: "https://en.wikipedia.org/wiki/Adam_Driver",
  },
  {
    answers: ["Chris Stapleton"],
    mustacheSrc: "./assets/mustaches/mustache(71).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/7/73/Chris_Stapleton_Concert_%2848519730107%29_%28cropped%29.jpg",
    license:
      "Steve Kwak - Maryland GovPics, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Chris_Stapleton_Concert_(48519730107)_(cropped).jpg",
    multipleChoice: ["Jonah Hill", "Jason Mamoa", "Tom Hardy"],
    bio: "https://en.wikipedia.org/wiki/Chris_Stapleton",
  },
  {
    answers: ["Andrew Garfield"],
    mustacheSrc: "./assets/mustaches/mustache(72).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/f/fe/Andrew_Garfield_in_2023_%28cropped%29.jpg",
    license:
      "https://www.flickr.com/photos/drlovell/, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Andrew_Garfield_in_2023_(cropped).jpg",
    multipleChoice: ["Tom Holland", "Ben Schwartz", "Robert Pattinson"],
    bio: "https://en.wikipedia.org/wiki/Andrew_Garfield",
  },
  {
    answers: ["Terry Crews"],
    mustacheSrc: "./assets/mustaches/mustache(73).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/e/ed/Terry_Crews_Get_Smart_premiere_arrival.jpg",
    license:
      "Fashion photographer Anthony Citrano at http://www.zigzaglens.com/, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Terry_Crews_Get_Smart_premiere_arrival.jpg",
    multipleChoice: ["Tyler Perry", "Kevin Hart", "Jamie Foxx"],
    bio: "https://en.wikipedia.org/wiki/Terry_Crews",
  },
  {
    answers: ["Allen Iverson"],
    mustacheSrc: "./assets/mustaches/mustache(74).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/02/Allen_Iverson_08.jpg",
    license:
      "Keith Allison, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Allen_Iverson_08.jpg",
    multipleChoice: ["Ludacris", "LL Cool J", "Kawhi Leonard"],
    bio: "https://en.wikipedia.org/wiki/Allen_Iverson",
  },
  {
    answers: ["Matt Damon"],
    mustacheSrc: "./assets/mustaches/mustache(75).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/a8/Matt_Damon_at_Berlinale_2024_Ausschnitt.jpg",
    license:
      "Elena Ternovaja, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Matt_Damon_at_Berlinale_2024_Ausschnitt.jpg",
    multipleChoice: ["Edward Norton", "Brad Pitt", "Chris Pine"],
    bio: "https://en.wikipedia.org/wiki/Matt_Damon",
  },
  {
    answers: ["Will Smith"],
    mustacheSrc: "./assets/mustaches/mustache(76).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/3f/TechCrunch_Disrupt_2019_%2848834434641%29_%28cropped%29.jpg",
    license:
      "TechCrunch, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:TechCrunch_Disrupt_2019_(48834434641)_(cropped).jpg",
    multipleChoice: ["Anthony Mackie", "Michael B Jordan", "Mahershala Ali"],
    bio: "https://en.wikipedia.org/wiki/Will_Smith",
  },
  {
    answers: ["Michael Jordan"],
    mustacheSrc: "./assets/mustaches/mustache(77).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/8/88/Michael_Jordan.jpg",
    license:
      "Joshua Massel.  Cropped by en:User:Quadzilla99, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Michael_Jordan.jpg",
    multipleChoice: ["Bill Russell", "Evander Holyfield", "Idris Elba"],
    bio: "https://en.wikipedia.org/wiki/Michael_Jordan",
  },
  {
    answers: ["Russell Crowe"],
    mustacheSrc: "./assets/mustaches/mustache(78).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/d/d8/Russell_Crowe_%2834450751620%29.jpg",
    license:
      "Eva Rinaldi from Sydney Australia, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Russell_Crowe_(34450751620).jpg",
    multipleChoice: ["Robert De Niro", "Kurt Russell", "John Goodman"],
    bio: "https://en.wikipedia.org/wiki/Russell_Crowe",
  },
  {
    answers: ["Chris Rock"],
    mustacheSrc: "./assets/mustaches/mustache(79).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/5/57/Chris_Rock_WE_2012_Shankbone.JPG",
    license:
      "David Shankbone, CC BY 3.0 <https://creativecommons.org/licenses/by/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Chris_Rock_WE_2012_Shankbone.JPG",
    multipleChoice: ["Chris Tucker", "Kevin Hart", "Eddie Murphy"],
    bio: "https://en.wikipedia.org/wiki/Chris_Rock",
  },
  {
    answers: ["Stephen Curry"],
    mustacheSrc: "./assets/mustaches/mustache(80).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/aa/TechCrunch_Disrupt_2019_%2848834853256%29_%281%29.jpg",
    license:
      "TechCrunch, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:TechCrunch_Disrupt_2019_(48834853256)_(1).jpg",
    multipleChoice: ["Shaun Livingston", "Kelly Oubre Jr", "Drake"],
    bio: "https://en.wikipedia.org/wiki/Stephen_Curry",
  },
  {
    answers: ["Philip Seymour Hoffman"],
    mustacheSrc: "./assets/mustaches/mustache(81).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/f/f5/Philip_Seymour_Hoffman_2011.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Philip_Seymour_Hoffman_2011.jpg",
    multipleChoice: ["Jesse Plemons", "David Letterman", "Jim Gaffigan"],
    bio: "https://en.wikipedia.org/wiki/Philip_Seymour_Hoffman",
  },
  {
    answers: ["Javier Bardem"],
    mustacheSrc: "./assets/mustaches/mustache(82).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/f/f5/Premios_Goya_2018_-_Javier_Bardem.jpg",
    license:
      "Carlos Delgado, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Premios_Goya_2018_-_Javier_Bardem.jpg",
    multipleChoice: ["Benicio Del Toro", "Robert Downey Jr", "Jon Hamm"],
    bio: "https://en.wikipedia.org/wiki/Javier_Bardem",
  },
  {
    answers: ["Viggo Mortensen"],
    mustacheSrc: "./assets/mustaches/mustache(83).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/5/54/Viggo_Mortensen_Cannes.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Viggo_Mortensen_Cannes.jpg",
    multipleChoice: ["Matthew McConaughey", "William H. Macy", "Tim Allen"],
    bio: "https://en.wikipedia.org/wiki/Viggo_Mortensen",
  },
  {
    answers: ["Danny McBride"],
    mustacheSrc: "./assets/mustaches/mustache(84).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/d/d6/DannyRMcBrideMar09.jpg",
    license:
      "highcastle photography, CC BY 3.0 <https://creativecommons.org/licenses/by/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:DannyRMcBrideMar09.jpg",
    multipleChoice: ["Jonah Hill", "John Goodman", "Seth Rogen"],
    bio: "https://en.wikipedia.org/wiki/Danny_McBride",
  },
  {
    answers: ["Michael Strahan"],
    mustacheSrc: "./assets/mustaches/mustache(85).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/f/fa/Michael_Strahan_2022_%28cropped%29.jpg",
    license:
      "Airman 1st Class Andrew Britten, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Michael_Strahan_2022_(cropped).jpg",
    multipleChoice: ["Jamie Foxx", "Anthony Mackie", "Eddie Murphy"],
    bio: "https://en.wikipedia.org/wiki/Michael_Strahan",
  },
  {
    answers: ["Guy Fieri"],
    mustacheSrc: "./assets/mustaches/mustache(86).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/a2/Guy_Fieri.jpg",
    license:
      "Ranzokhel, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Guy_Fieri.jpg",
    multipleChoice: ["Eric Stonestreet", "Jack Black", "Joey Fatone"],
    bio: "https://en.wikipedia.org/wiki/Guy_Fieri",
  },
  {
    answers: ["Jeffrey Wright"],
    mustacheSrc: "./assets/mustaches/mustache(87).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/e/e6/Jeffrey_Wright_%2848452499917%29.jpg",
    license:
      "Gage Skidmore from Peoria, AZ, United States of America, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jeffrey_Wright_(48452499917).jpg",
    multipleChoice: ["John David Washington", "Laurence Fishburne", "James Earl Jones"],
    bio: "https://en.wikipedia.org/wiki/Jeffrey_Wright",
  },
  {
    answers: ["Sylvester Stallone"],
    mustacheSrc: "./assets/mustaches/mustache(88).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/c/c2/Flickr_-_nicogenin_-_66%C3%A8me_Festival_de_Venise_%28Mostra%29_-_Sylvester_Stallone_%2826%29.jpg",
    license:
      "nicolas genin from Paris, France, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Flickr_-_nicogenin_-_66%C3%A8me_Festival_de_Venise_(Mostra)_-_Sylvester_Stallone_(26).jpg",
    multipleChoice: ["Robert De Niro", "Al Pacino", "Arnold Schwarzenegger"],
    bio: "https://en.wikipedia.org/wiki/Sylvester_Stallone",
  },
  {
    answers: ["Billy Joel"],
    mustacheSrc: "./assets/mustaches/mustache(89).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/1/19/Billy_Joel_Shankbone_NYC_2009.jpg",
    license:
      "David Shankbone, CC BY 3.0 <https://creativecommons.org/licenses/by/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Billy_Joel_Shankbone_NYC_2009.jpg",
    multipleChoice: ["Eric Stonestreet", "Elton John", "Tom Hanks"],
    bio: "https://en.wikipedia.org/wiki/Billy_Joel",
  },
  {
    answers: ["William H. Macy"],
    mustacheSrc: "./assets/mustaches/mustache(90).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/2/2c/WilliamHMacyHWoFMar2012.jpg",
    license:
      "Gage Skidmore from Peoria, AZ, United States of America, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "Angela George, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    multipleChoice: ["Steve Buscemi", "Steve Zahn", "Owen Wilson"],
    bio: "https://en.wikipedia.org/wiki/William_H._Macy",
  },
  {
    answers: ["Tim Allen"],
    mustacheSrc: "./assets/mustaches/mustache(91).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/c/c7/Mug_Shot_of_Tim_Allen.jpg",
    license:
      "Kalamazoo Michigan Sheriff’s Department, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Mug_Shot_of_Tim_Allen.jpg",
    multipleChoice: ["Sam Elliot", "Steve Martin", "Tom Hanks"],
    bio: "https://en.wikipedia.org/wiki/Tim_Allen",
  },
  {
    answers: ["Dwayne Johnson", "The Rock"],
    mustacheSrc: "./assets/mustaches/mustache(92).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/6/68/Dwayne_Johnson_at_the_2009_Tribeca_Film_Festival.jpg",
    license:
      "David Shankbone, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Dwayne_Johnson_at_the_2009_Tribeca_Film_Festival.jpg",
    multipleChoice: ["Dave Bautista", "Laurence Fishburne", "Shemar Moore"],
    bio: "https://en.wikipedia.org/wiki/Dwayne_Johnson",
  },
  {
    answers: ["Daniel Radcliffe"],
    mustacheSrc: "./assets/mustaches/mustache(93).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/3b/Daniel_Radcliffe_in_July_2015.jpg",
    license:
      "Gage Skidmore from Peoria, AZ, United States of America, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Daniel_Radcliffe_in_July_2015.jpg",
    multipleChoice: ["Aaron Taylor-Johnson", "Justin Timberlake", "Tom Hiddleston"],
    bio: "https://en.wikipedia.org/wiki/Daniel_Radcliffe",
  },
  {
    answers: ["Mel Gibson"],
    mustacheSrc: "./assets/mustaches/mustache(94).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/5/58/Mel_Gibson_Cannes_2016.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Mel_Gibson_Cannes_2016.jpg",
    multipleChoice: ["George Clooney", "John Krasinski", "Nick Nolte"],
    bio: "https://en.wikipedia.org/wiki/Mel_Gibson",
  },
  {
    answers: ["Nicolas Cage"],
    mustacheSrc: "./assets/mustaches/mustache(95).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/f/f3/Nicolas_Cage_-_66%C3%A8me_Festival_de_Venise_%28Mostra%29.jpg",
    license:
      "nicolas genin, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Nicolas_Cage_-_66%C3%A8me_Festival_de_Venise_(Mostra).jpg",
    multipleChoice: ["Dave Grohl", "Francis Ford Coppola", "John Travolta"],
    bio: "https://en.wikipedia.org/wiki/Nicolas_Cage",
  },
  {
    answers: ["William Howard Taft"],
    mustacheSrc: "./assets/mustaches/mustache(96).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/39/William_Howard_Taft%2C_head-and-shoulders_portrait%2C_facing_front.jpg",
    license:
      "See page for author, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:William_Howard_Taft,_head-and-shoulders_portrait,_facing_front.jpg",
    multipleChoice: ["Theodore Roosevelt", "Grover Cleveland", "Martin Van Buren"],
    bio: "https://en.wikipedia.org/wiki/William_Howard_Taft",
  },
  {
    answers: ["Adam Sandler"],
    mustacheSrc: "./assets/mustaches/mustache(97).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/4/40/Adam_Sandler_Cannes_2017.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Adam_Sandler_Cannes_2017.jpg",
    multipleChoice: ["Ben Stiller", "Kevin James", "Jeff Goldblum"],
    bio: "https://en.wikipedia.org/wiki/Adam_Sandler",
  },
  {
    answers: ["John Malkovich"],
    mustacheSrc: "./assets/mustaches/mustache(98).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/0d/John_Malkovich%2C1994_02.jpg",
    license:
      "Gorupdebesanez, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:John_Malkovich,1994_02.jpg",
    multipleChoice: ["J. K. Simmons", "John Turturro", "Steve Buscemi"],
    bio: "https://en.wikipedia.org/wiki/John_Malkovich",
  },
  {
    answers: ["Heath Ledger"],
    mustacheSrc: "./assets/mustaches/mustache(99).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/4/4f/Heath_Ledger.jpg",
    license:
      "Howie, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Heath_Ledger.jpg",
    multipleChoice: ["Johnny Depp", "Joseph Gordon-Levitt", "Chris Pratt"],
    bio: "https://en.wikipedia.org/wiki/Heath_Ledger",
  },
  {
    answers: ["Chris Evans"],
    mustacheSrc: "./assets/mustaches/mustache(100).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/33/Mark_Kassen%2C_Tony_C%C3%A1rdenas_and_Chris_Evans_%28cropped%29.jpg",
    license:
      "Rosa Pineda, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Mark_Kassen,_Tony_C%C3%A1rdenas_and_Chris_Evans_(cropped).jpg",
    multipleChoice: ["Jimmy Fallon", "John Krasinski", "Justin Timberlake"],
    bio: "https://en.wikipedia.org/wiki/Chris_Evans_(actor)",
  },
  {
    answers: ["Dev Patel"],
    mustacheSrc: "./assets/mustaches/mustache(101).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/c/c7/SXSW_2024_-_Dev_Patel_2_%28cropped%29.jpg",
    license:
      "Ariela Ortiz Barrantes, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:SXSW_2024_-_Dev_Patel_2_(cropped).jpg",
    multipleChoice: ["Riz Ahmed", "Kumail Ali Nanjiani", "Oscar Isaac"],
    bio: "https://en.wikipedia.org/wiki/Dev_Patel",
  },
  {
    answers: ["Patrick Mahomes"],
    mustacheSrc: "./assets/mustaches/mustache(102).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/9/92/Patrick_Mahomes_%2851615475056%29.jpg",
    license:
      "All-Pro Reels from District of Columbia, USA, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Patrick_Mahomes_(51615475056).jpg",
    multipleChoice: ["Eric Andre", "Danny McBride", "Stephen Curry"],
    bio: "https://en.wikipedia.org/wiki/Patrick_Mahomes",
  },
  {
    answers: ["James Franco"],
    mustacheSrc: "./assets/mustaches/mustache(103).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/6/6a/James_Franco_%28Cropped%29.jpg",
    license:
      "Vanessa Lua, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:James_Franco_(Cropped).jpg",
    multipleChoice: ["Dave Franco", "Seth Rogen", "Aaron Taylor-Johnson"],
    bio: "https://en.wikipedia.org/wiki/James_Franco",
  },
  {
    answers: ["Dr. Phil"],
    mustacheSrc: "./assets/mustaches/mustache(104).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/6/6a/Prince_Avenue_Mascot.jpg",
    license:
      "Dr. Phil, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Prince_Avenue_Mascot.jpg",
    multipleChoice: ["Danny DeVito", "Sam Elliot", "Dave Bautista"],
    bio: "https://en.wikipedia.org/wiki/Phil_McGraw",
  },
  {
    answers: ["Bryan Cranston"],
    mustacheSrc: "./assets/mustaches/mustache(105).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/9/96/Bryan_Cranston_2022.jpg",
    license:
      "Philip Romano, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Bryan_Cranston_2022.jpg",
    multipleChoice: ["David Letterman", "David Cross", "Jim Carrey"],
    bio: "https://en.wikipedia.org/wiki/Bryan_Cranston",
  },
  {
    answers: ["John Lennon"],
    mustacheSrc: "./assets/mustaches/mustache(106).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/8/85/John_Lennon_1969_%28cropped%29.jpg",
    license:
      "Joost Evers / Anefo, CC0, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:John_Lennon_1969_(cropped).jpg",
    multipleChoice: ["Ringo Starr", "Paul McCartney", "George Harrison"],
    bio: "https://en.wikipedia.org/wiki/John_Lennon",
  },
  {
    answers: ["Brad Pitt"],
    mustacheSrc: "./assets/mustaches/mustache(107).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/5/51/Brad_Pitt_Fury_2014.jpg",
    license:
      "DoD News Features, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Brad_Pitt_Fury_2014.jpg",
    multipleChoice: ["Tom Cruise", "Tom Brady", "Tom Hanks"],
    bio: "https://en.wikipedia.org/wiki/John_Lennon",
  },
  {
    answers: ["Danny Trejo"],
    mustacheSrc: "./assets/mustaches/mustache(108).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/b/bb/Danny_Trejo_by_Gage_Skidmore.jpg",
    license:
      "Gage Skidmore, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Danny_Trejo_by_Gage_Skidmore.jpg",
    multipleChoice: ["Antonio Banderas", "Javier Bardem", "Albert Pujols"],
    bio: "https://en.wikipedia.org/wiki/Danny_Trejo",
  },
  {
    answers: ["Jon Voight"],
    mustacheSrc: "./assets/mustaches/mustache(109).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/a6/Jon_Voight_Cannes.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jon_Voight_Cannes.jpg",
    multipleChoice: ["Willie Nelson", "Paul Newman", "Eric Stonestreet"],
    bio: "https://en.wikipedia.org/wiki/Jon_Voight",
  },
  {
    answers: ["Stan Lee"],
    mustacheSrc: "./assets/mustaches/mustache(110).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/4/44/Stan_Lee_%285774464408%29.jpg",
    license:
      "Gage Skidmore from Peoria, AZ, United States of America, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Stan_Lee_(5774464408).jpg",
    multipleChoice: ["Sam Elliot", "Gary Oldman", "Nick Nolte"],
    bio: "https://en.wikipedia.org/wiki/Stan_Lee",
  },
  {
    answers: ["Dave Grohl"],
    mustacheSrc: "./assets/mustaches/mustache(111).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/07/FoosDublin210819-2_%2848620472807%29_%28cropped%29.jpg",
    license:
      "Raphael Pour-Hashemi, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:FoosDublin210819-2_(48620472807)_(cropped).jpg",
    multipleChoice: ["Gardner Minshew", "Keanu Reeves", "Christian Bale"],
    bio: "https://en.wikipedia.org/wiki/Dave_Grohl",
  },
  {
    answers: ["Snoop Dogg"],
    mustacheSrc: "./assets/mustaches/mustache(112).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/1/17/Snoop_Dogg_2019_by_Glenn_Francis.jpg",
    license:
      "Toglenn, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Snoop_Dogg_2019_by_Glenn_Francis.jpg",
    multipleChoice: ["50 Cent", "Wiz Khalifa", "Busta Rhymes"],
    bio: "https://en.wikipedia.org/wiki/Snoop_Dogg",
  },
  {
    answers: ["Lamorne Morris"],
    mustacheSrc: "./assets/mustaches/mustache(113).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/5/54/Lamorne_Morris_by_Gage_Skidmore.jpg",
    license:
      "Gage Skidmore, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Lamorne_Morris_by_Gage_Skidmore.jpg",
    multipleChoice: ["Michael Strahan", "Anthony Mackie", "Jamie Foxx"],
    bio: "https://en.wikipedia.org/wiki/Lamorne_Morris",
  },
  {
    answers: ["Gary Oldman"],
    mustacheSrc: "./assets/mustaches/mustache(114).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/d/da/Gary_Oldman_at_the_London_premiere_of_Tinker_Tailor_Soldier_Spy.png",
    license:
      "Sam Hughes, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Gary_Oldman_at_the_London_premiere_of_Tinker_Tailor_Soldier_Spy.png",
    multipleChoice: ["Sam Rockwell", "Jeff Bridges", "Johnny Depp"],
    bio: "https://en.wikipedia.org/wiki/Gary_Oldman",
  },
  {
    answers: ["Ron Howard"],
    mustacheSrc: "./assets/mustaches/mustache(115).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/3f/Ron_Howard_2011_Shankbone_3.JPG",
    license:
      "David Shankbone, CC BY 3.0 <https://creativecommons.org/licenses/by/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Ron_Howard_2011_Shankbone_3.JPG",
    multipleChoice: ["Alan Tudyk", "Jesse Tyler Ferguson", "Michael Fassbender"],
    bio: "https://en.wikipedia.org/wiki/Ron_Howard",
  },
  {
    answers: ["Kit Harington"],
    mustacheSrc: "./assets/mustaches/mustache(116).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/32/Kit_harrington_by_sachyn_mital_%28cropped_2%29.jpg",
    license:
      "Sachyn, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Kit_harrington_by_sachyn_mital_(cropped_2).jpg",
    multipleChoice: ["Tom Hardy", "Shia Labeouf", "Tom Hiddleston"],
    bio: "https://en.wikipedia.org/wiki/Kit_Harington",
  },
  {
    answers: ["Seth Rogen"],
    mustacheSrc: "./assets/mustaches/mustache(117).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/1/19/Seth_Rogen_by_Gage_Skidmore_2.jpg",
    license:
      "Gage Skidmore, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Seth_Rogen_by_Gage_Skidmore_2.jpg",
    multipleChoice: ["Jonah Hill", "James Franco", "Zach Galifianakis"],
    bio: "https://en.wikipedia.org/wiki/Seth_Rogen",
  },
  {
    answers: ["Joel McHale"],
    mustacheSrc: "./assets/mustaches/mustache(118).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/5/5b/Joel_McHale_by_Gage_Skidmore.jpg",
    license:
      "Gage Skidmore, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Joel_McHale_by_Gage_Skidmore.jpg",
    multipleChoice: ["Ryan Seacrest", "Dax Shepard", "Daniel Tosh"],
    bio: "https://en.wikipedia.org/wiki/Joel_McHale",
  },
  {
    answers: ["Tom Hiddleston"],
    mustacheSrc: "./assets/mustaches/mustache(119).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/a9/Tom_Hiddleston_%2848468962561%29_%28cropped%29.jpg",
    license:
      "Gage Skidmore from Peoria, AZ, United States of America, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Tom_Hiddleston_(48468962561)_(cropped).jpg",
    multipleChoice: ["Tom Hardy", "Tom Hanks", "Tom Holland"],
    bio: "https://en.wikipedia.org/wiki/Tom_Hiddleston",
  },
  {
    answers: ["Tobey Maguire"],
    mustacheSrc: "./assets/mustaches/mustache(120).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/c/c2/Tobey_Maguire_2014.jpg",
    license:
      "gdcgraphics, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Tobey_Maguire_2014.jpg",
    multipleChoice: ["Jake Gyllenhaal", "Shia Labeouf", "Daniel Radcliffe"],
    bio: "https://en.wikipedia.org/wiki/Tobey_Maguire",
  },
  {
    answers: ["Willem Dafoe"],
    mustacheSrc: "./assets/mustaches/mustache(121).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/6/6d/MJK_12326_Willem_Dafoe_%28Berlinale_2018%29.jpg",
    license:
      "Martin Kraft, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:MJK_12326_Willem_Dafoe_(Berlinale_2018).jpg",
    multipleChoice: ["Viggo Mortensen", "Burt Reynolds", "Christopher Walken"],
    bio: "https://en.wikipedia.org/wiki/Willem_Dafoe",
  },
  {
    answers: ["Nicolas Cage"],
    mustacheSrc: "./assets/mustaches/mustache(122).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/f/f8/Festival_de_cinema_de_Sitges_2018_%2830240292207%29_%28cropped%29.jpg",
    license:
      "Moritz Barcelona from Barcelona, Catalunya, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Festival_de_cinema_de_Sitges_2018_(30240292207)_(cropped).jpg",
    multipleChoice: ["John Kusack", "John Malkovich", "John Cena"],
    bio: "https://en.wikipedia.org/wiki/Nicolas_Cage",
  },
  {
    answers: ["Dennis Hopper"],
    mustacheSrc: "./assets/mustaches/mustache(123).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/e/ee/Dennis_Hopper_%2840362354693%29.jpg",
    license:
      "John Mathew Smith & www.celebrity-photos.com from Laurel  Maryland, USA, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Dennis_Hopper_(40362354693).jpg",
    multipleChoice: ["Bryan Cranston", "David Lynch", "William Daniels"],
    bio: "https://en.wikipedia.org/wiki/Dennis_Hopper",
  },
  {
    answers: ["Dale Earnhardt"],
    mustacheSrc: "./assets/mustaches/mustache(124).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/DaleEarnhardtSunglassesDriversSuit.jpg",
    license:
      "Darryl Moran, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:DaleEarnhardtSunglassesDriversSuit.jpg",
    multipleChoice: ["Jeff Foxworthy", "Tom Selleck", "Henry Cavill"],
    bio: "https://en.wikipedia.org/wiki/Dale_Earnhardt",
  },
  {
    answers: ["John Waters"],
    mustacheSrc: "./assets/mustaches/mustache(125).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/aa/John_Waters_1995.jpg",
    license:
      "John Mathew Smith & www.celebrity-photos.com from Laurel  Maryland, USA, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:John_Waters_1995.jpg",
    multipleChoice: ["Steve Buscemi", "Steve Martin", "Bob Dylan"],
    bio: "https://en.wikipedia.org/wiki/John_Waters",
  },
  {
    answers: ["Gene Hackman"],
    mustacheSrc: "./assets/mustaches/mustache(126).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/3a/Gene_Hackman_-_1972.jpg",
    license:
      "Chicago Daily News, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Gene_Hackman_-_1972.jpg",
    multipleChoice: ["Walt Disney", "Henry Ford", "Andy Griffith"],
    bio: "https://en.wikipedia.org/wiki/Gene_Hackman",
  },
  {
    answers: ["Laurence Fishburne"],
    mustacheSrc: "./assets/mustaches/mustache(127).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/7/76/Lawrence_Fishbourne_2000.jpg",
    license:
      "Kingkongphoto & www.celebrity-photos.com from Laurel  Maryland, USA, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Lawrence_Fishbourne_2000.jpg",
    multipleChoice: ["Samuel L Jackson", "Steve Harvey", "Denzel Washington"],
    bio: "https://en.wikipedia.org/wiki/Laurence_Fishburne",
  },
  {
    answers: ["Jonah Hill"],
    mustacheSrc: "./assets/mustaches/mustache(128).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/0e/Jonah_Hill-4939_%28cropped%29_%28cropped%29.jpg",
    license:
      "Harald Krichel, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jonah_Hill-4939_(cropped)_(cropped).jpg",
    multipleChoice: ["Zach Galifianakis", "Seth Rogen", "James Franco"],
    bio: "https://en.wikipedia.org/wiki/Jonah_Hill",
  },
  {
    answers: ["Friedrich Nietzsche"],
    mustacheSrc: "./assets/mustaches/mustache(129).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/1/1b/Nietzsche187a.jpg",
    license:
      "Friedrich Hermann Hartmann, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Nietzsche187a.jpg",
    multipleChoice: ["William Howard Taft", "Theodore Roosevelt", "Walt Whitman"],
    bio: "https://en.wikipedia.org/wiki/Friedrich_Nietzsche",
  },
  {
    answers: ["Donald Sutherland"],
    mustacheSrc: "./assets/mustaches/mustache(130).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/c/ce/Donald_Sutherland%2C_1981.jpg",
    license:
      "Alan Light, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Donald_Sutherland,_1981.jpg",
    multipleChoice: ["Jon Voight", "Tom Selleck", "Andy Griffith"],
    bio: "https://en.wikipedia.org/wiki/Donald_Sutherland",
  },
  {
    answers: ["Stephen Colbert"],
    mustacheSrc: "./assets/mustaches/mustache(131).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/9/95/Stephen_Colbert_June_2023.jpg",
    license:
      "Montclair Film, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Stephen_Colbert_June_2023.jpg",
    multipleChoice: ["David Letterman", "Paul Giamatti", "Tom Hanks"],
    bio: "https://en.wikipedia.org/wiki/Stephen_Colbert",
  },
  {
    answers: ["Rainn Wilson"],
    mustacheSrc: "./assets/mustaches/mustache(132).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/f/f9/Rainn_Wilson_2016.jpg",
    license:
      "Tai Lopez, CC BY 3.0 <https://creativecommons.org/licenses/by/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Rainn_Wilson_2016.jpg",
    multipleChoice: ["Rick Rubin", "Justin Timberlake", "Paul Giamatti"],
    bio: "https://en.wikipedia.org/wiki/Rainn_Wilson",
  },
  {
    answers: ["Steve Carell"],
    mustacheSrc: "./assets/mustaches/mustache(133).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/f/fd/Steve_Carell_2_2013.jpg",
    license:
      "Eva Rinaldi, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Steve_Carell_2_2013.jpg",
    multipleChoice: ["Stephen Colbert", "Joseph Gordon-Levitt", "David Bowie"],
    bio: "https://en.wikipedia.org/wiki/Steve_Carell",
  },
  {
    answers: ["Bill Murray"],
    mustacheSrc: "./assets/mustaches/mustache(134).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/e/e0/Bill_Murray_at_2013_Military_Day_at_AT%26T_Pebble_Beach_Pro-Am_%28cropped%29.jpg",
    license:
      "Presidio of Monterey, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Bill_Murray_at_2013_Military_Day_at_AT%26T_Pebble_Beach_Pro-Am_(cropped).jpg",
    multipleChoice: ["Josh Brolin", "Steven Seagal", "Michael Douglas"],
    bio: "https://en.wikipedia.org/wiki/Bill_Murray",
  },
  {
    answers: ["Gerard Butler"],
    mustacheSrc: "./assets/mustaches/mustache(135).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/5/53/Gerard_Butler_%2829681162176%29.jpg",
    license:
      "Gordon Correll, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Gerard_Butler_(29681162176).jpg",
    multipleChoice: ["Ben Affleck", "Pierce Brosnan", "Russell Crowe"],
    bio: "https://en.wikipedia.org/wiki/Gerard_Butler",
  },
  {
    answers: ["Steven Spielberg"],
    mustacheSrc: "./assets/mustaches/mustache(136).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/4/4d/MKr25402_Steven_Spielberg_%28Berlinale_2023%29.jpg",
    license:
      "Martin Kraft, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:MKr25402_Steven_Spielberg_(Berlinale_2023).jpg",
    multipleChoice: ["Larry David", "Stephen Colbert", "David Letterman"],
    bio: "https://en.wikipedia.org/wiki/Steven_Spielberg",
  },
  {
    answers: ["Sam Rockwell"],
    mustacheSrc: "./assets/mustaches/mustache(137).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/d/d3/Sam_Rockwell_%2851492%29_%28cropped%29.jpg",
    license:
      "Rhododendrites, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Sam_Rockwell_(51492)_(cropped).jpg",
    multipleChoice: ["Christian Bale", "Jake Gyllenhaal", "James Franco"],
    bio: "https://en.wikipedia.org/wiki/Sam_Rockwell",
  },
  {
    answers: ["Ben Kingsley"],
    mustacheSrc: "./assets/mustaches/mustache(138).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/32/Sir_Ben_Kingsley_by_David_Shankbone.jpg",
    license:
      "David Shankbone, CC BY-SA 3.0 <http://creativecommons.org/licenses/by-sa/3.0/>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Sir_Ben_Kingsley_by_David_Shankbone.jpg",
    multipleChoice: ["Sean Penn", "Tim Robbins", "Larry David"],
    bio: "https://en.wikipedia.org/wiki/Ben_Kingsley",
  },
  {
    answers: ["Tommy Lee Jones"],
    mustacheSrc: "./assets/mustaches/mustache(139).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/6/66/Tommy_Lee_Jones_Cannes.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Tommy_Lee_Jones_Cannes.jpg",
    multipleChoice: ["Tom Selleck", "John Travolta", "Jeff Bridges"],
    bio: "https://en.wikipedia.org/wiki/Tommy_Lee_Jones",
  },
  {
    answers: ["Peter Dinklage"],
    mustacheSrc: "./assets/mustaches/mustache(140).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/a4/Peter_Dinklage-34.jpg",
    license:
      "Harald Krichel, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Peter_Dinklage-34.jpg",
    multipleChoice: ["Henry Cavill", "Bill Hader", "Tom Hardy"],
    bio: "https://en.wikipedia.org/wiki/Peter_Dinklage",
  },
  {
    answers: ["James McAvoy"],
    mustacheSrc: "./assets/mustaches/mustache(141).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/e/e3/James_McAvoy_Cannes_2014.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:James_McAvoy_Cannes_2014.jpg",
    multipleChoice: ["Justin Timberlake", "Jimmy Fallon", "Michael Fassbender"],
    bio: "https://en.wikipedia.org/wiki/James_McAvoy",
  },
  {
    answers: ["Mike Ditka"],
    mustacheSrc: "./assets/mustaches/mustache(142).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/c/c3/Mike_Ditka_2008_NFL_Experience_48-DPA-02_01_02_08.jpg",
    license:
      "Tami Heilemann--Interior Staff, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Mike_Ditka_2008_NFL_Experience_48-DPA-02_01_02_08.jpg",
    multipleChoice: ["Andy Reid", "John Madden", "Eric Stonestreet"],
    bio: "https://en.wikipedia.org/wiki/Mike_Ditka",
  },
  {
    answers: ["John Malkovich"],
    mustacheSrc: "./assets/mustaches/mustache(98).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/0d/John_Malkovich%2C1994_02.jpg",
    license:
      "Gorupdebesanez, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:John_Malkovich,1994_02.jpg",
    multipleChoice: ["J. K. Simmons", "John Turturro", "Steve Buscemi"],
    bio: "https://en.wikipedia.org/wiki/John_Malkovich",
  },
  {
    answers: ["Jake Gyllenhaal"],
    mustacheSrc: "./assets/mustaches/mustache(43).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/0e/Jake_Gyllenhaal_2019_by_Glenn_Francis.jpg",
    license:
      "Toglenn, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jake_Gyllenhaal_2019_by_Glenn_Francis.jpg",
    multipleChoice: ["Tobey Maguire", "Jared Leto", "Christian Bale"],
    bio: "https://en.wikipedia.org/wiki/Jake_Gyllenhaal",
  },
  {
    answers: ["Brad Pitt", "Bradley Pitt", "Brad Pit", "Angelina's husband"],
    mustacheSrc: "./assets/mustaches/mustache(8).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/9/98/Brad_Pitt_in_2014_%28cropped%29.jpg",
    license:
      "DoD News Features, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source:
      "https://commons.wikimedia.org/wiki/File:Brad_Pitt_in_2014_(cropped).jpg",
    multipleChoice: ["Tom Cruise", "Owen Wilson", "Charlie Hunnam"],
    bio: "https://en.wikipedia.org/wiki/Brad_Pitt",
  },
  {
    answers: ["Pedro Pascal"],
    mustacheSrc: "./assets/mustaches/mustache(34).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/8/87/Pedro_Pascal_SDCC_2014.jpg",
    license:
      "Gage Skidmore, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Pedro_Pascal_SDCC_2014.jpg",
    multipleChoice: ["Chris Pratt", "Oscar Isaac", "Paul Mescal"],
    bio: "https://en.wikipedia.org/wiki/Pedro_Pascal",
  },
  {
    answers: ["David Letterman", "Dave Letterman"],
    mustacheSrc: "./assets/mustaches/mustache(41).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/d/de/David_letterman_%2847966353856%29.jpg",
    license:
      "Zach Catanzareti Photo, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:David_letterman_(47966353856).jpg",
    multipleChoice: ["Anthony Hopkins", "Donald Sutherland", "J.K. Simmons"],
    bio: "https://en.wikipedia.org/wiki/David_Letterman",
  },
  {
    answers: ["Allen Iverson"],
    mustacheSrc: "./assets/mustaches/mustache(74).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/02/Allen_Iverson_08.jpg",
    license:
      "Keith Allison, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Allen_Iverson_08.jpg",
    multipleChoice: ["Ludacris", "LL Cool J", "Kawhi Leonard"],
    bio: "https://en.wikipedia.org/wiki/Allen_Iverson",
  },
  {
    answers: ["Robert Downey Jr.", "RDJ"],
    mustacheSrc: "./assets/mustaches/mustache(33).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/0a/Robert_Downey_Jr_avp_Iron_Man_3_Paris.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Robert_Downey_Jr_avp_Iron_Man_3_Paris.jpg",
    multipleChoice: ["Javier Bardem", "Johnny Depp", "Quentin Tarantino"],
    bio: "https://en.wikipedia.org/wiki/Robert_Downey_Jr.",
  },
  {
    answers: ["Matthew McConaughey"],
    mustacheSrc: "./assets/mustaches/mustache(64).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/2/26/Matthew_McConaughey_Cannes_2015_2.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Matthew_McConaughey_Cannes_2015_2.jpg",
    multipleChoice: ["Colin Farrell", "Bradley Cooper", "Jason Bateman"],
    bio: "https://en.wikipedia.org/wiki/Matthew_McConaughey",
  },
  {
    answers: ["LeBron James"],
    mustacheSrc: "./assets/mustaches/mustache(62).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/7/7a/LeBron_James_%2851959977144%29_%28cropped2%29.jpg",
    license:
      "Erik Drost, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:LeBron_James_(51959977144)_(cropped2).jpg",
    multipleChoice: ["James Harden", "Anthony Davis", "Carlos Boozer"],
    bio: "https://en.wikipedia.org/wiki/LeBron_James",
  },
  {
    answers: [
      "Albert Einstein",
      "Einstein",
      "Al Einstein",
      "Einstien",
      "Albert Einstien",
      "Big albert",
      "Smart al",
      "big al",
    ],
    mustacheSrc: "./assets/mustaches/mustache(4).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/d/d3/Albert_Einstein_Head.jpg",
    license:
      "Photograph by Orren Jack Turner, Princeton, N.J. Modified with Photoshop by PM_Poon and later by Dantadd., Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Albert_Einstein_Head.jpg",
    multipleChoice: ["Friedrich Nietzsche", "Theodore Roosevelt", "Mark Twain"],
    bio: "https://en.wikipedia.org/wiki/Albert_Einstein",
  },
  {
    answers: ["Tom Hanks"],
    mustacheSrc: "./assets/mustaches/mustache(39).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/a9/Tom_Hanks_TIFF_2019.jpg",
    license:
      "John Bauld from Toronto, Canada, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Tom_Hanks_TIFF_2019.jpg",
    multipleChoice: ["Nick Nolte", "Anthony Hopkins", "Sean Connery"],
    bio: "https://en.wikipedia.org/wiki/Tom_Hanks",
  },
  {
    answers: ["William Howard Taft"],
    mustacheSrc: "./assets/mustaches/mustache(96).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/39/William_Howard_Taft%2C_head-and-shoulders_portrait%2C_facing_front.jpg",
    license:
      "See page for author, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:William_Howard_Taft,_head-and-shoulders_portrait,_facing_front.jpg",
    multipleChoice: ["Theodore Roosevelt", "Grover Cleveland", "Martin Van Buren"],
    bio: "https://en.wikipedia.org/wiki/William_Howard_Taft",
  },
  {
    answers: ["Michael Jordan"],
    mustacheSrc: "./assets/mustaches/mustache(77).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/8/88/Michael_Jordan.jpg",
    license:
      "Joshua Massel.  Cropped by en:User:Quadzilla99, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Michael_Jordan.jpg",
    multipleChoice: ["Bill Russell", "Evander Holyfield", "Idris Elba"],
    bio: "https://en.wikipedia.org/wiki/Michael_Jordan",
  },
  {
    answers: ["Jeff Bridges"],
    mustacheSrc: "./assets/mustaches/mustache(31).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/a1/Jeff_Bridges_by_Gage_Skidmore_3.jpg",
    license:
      "Gage Skidmore, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Jeff_Bridges_by_Gage_Skidmore_3.jpg",
    multipleChoice: ["Kurt Russell", "Willem Dafoe", "Josh Brolin"],
    bio: "https://en.wikipedia.org/wiki/Jeff_Bridges",
  },
  {
    answers: [
      "Martin Luther King Jr",
      "Martin Luther King Jr.",
      "Martin luther king",
      "dr king",
      "dr. king",
      "king",
      "luther",
      "mlk",
      "mlk jr",
      "mlkjr",
    ],
    mustacheSrc: "./assets/mustaches/mustache(5).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/0/05/Martin_Luther_King%2C_Jr..jpg",
    license: "Nobel Foundation, Public domain, via Wikimedia Commons",
    source:
      "https://commons.wikimedia.org/wiki/File:Martin_Luther_King,_Jr..jpg",
    multipleChoice: ["Barack Obama", "Nelson Mandela", "Malcom X"],
    bio: "https://en.wikipedia.org/wiki/Martin_Luther_King_Jr.",
  },
  {
    answers: [
      "Nick Offerman",
      "Ron Swanson",
      "Nicholas Offerman",
      "Ronald Swanson",
      "Swanson",
      "Offerman",
      "The guy from parks and rec",
    ],
    mustacheSrc: "./assets/mustaches/mustache(1).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Nick_Offerman_2013_Headshot_%28cropped%29.jpg/768px-Nick_Offerman_2013_Headshot_%28cropped%29.jpg?20140321145404",
    license:
      "UMBC Student Events Board, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source:
      "https://commons.wikimedia.org/wiki/File:Nick_Offerman_2013_Headshot_(cropped).jpg",
    multipleChoice: ["Ed Helms", "Zach Galifianakis", "Russell Crowe"],
    bio: "https://en.wikipedia.org/wiki/Nick_Offerman",
  },
  {
    answers: [
      "Colin Farrell",
      "Collin Farrell",
      "Colin Farell",
      "Colin Farel",
      "Colin Farrel",
      "Farrell",
      "Colin Frell",
    ],
    mustacheSrc: "./assets/mustaches/mustache(7).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/8/8b/Colin_Farrell_by_David_Shankbone.jpg",
    license:
      "David Shankbone, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source:
      "https://commons.wikimedia.org/wiki/File:Colin_Farrell_by_David_Shankbone.jpg",
    multipleChoice: ["Bradley Cooper", "John Krasinski", "Al Pacino"],
    bio: "https://en.wikipedia.org/wiki/Colin_Farrell",
  },
  {
    answers: ["Carl Weathers"],
    mustacheSrc: "./assets/mustaches/mustache(59).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/7/72/Carl_Weathers_%28cropped_3_by_4%29.jpg",
    license:
      "Luigi Novi, CC BY 4.0 <https://creativecommons.org/licenses/by/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Carl_Weathers_(cropped_3_by_4).jpg",
    multipleChoice: ["Jamie Foxx", "Will Smith", "Eddie Murphy"],
    bio: "https://en.wikipedia.org/wiki/Carl_Weathers",
  },
  {
    answers: ["Mel Gibson"],
    mustacheSrc: "./assets/mustaches/mustache(94).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/5/58/Mel_Gibson_Cannes_2016.jpg",
    license:
      "Georges Biard, CC BY-SA 3.0 <https://creativecommons.org/licenses/by-sa/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Mel_Gibson_Cannes_2016.jpg",
    multipleChoice: ["George Clooney", "John Krasinski", "Nick Nolte"],
    bio: "https://en.wikipedia.org/wiki/Mel_Gibson",
  },
  {
    answers: ["Billy Joel"],
    mustacheSrc: "./assets/mustaches/mustache(89).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/1/19/Billy_Joel_Shankbone_NYC_2009.jpg",
    license:
      "David Shankbone, CC BY 3.0 <https://creativecommons.org/licenses/by/3.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Billy_Joel_Shankbone_NYC_2009.jpg",
    multipleChoice: ["Eric Stonestreet", "Elton John", "Tom Hanks"],
    bio: "https://en.wikipedia.org/wiki/Billy_Joel",
  },
  {
    answers: ["Guy Fieri"],
    mustacheSrc: "./assets/mustaches/mustache(86).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/a/a2/Guy_Fieri.jpg",
    license:
      "Ranzokhel, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Guy_Fieri.jpg",
    multipleChoice: ["Eric Stonestreet", "Jack Black", "Joey Fatone"],
    bio: "https://en.wikipedia.org/wiki/Guy_Fieri",
  },
  {
    answers: ["Sean Penn"],
    mustacheSrc: "./assets/mustaches/mustache(40).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/8/88/Seanpenn1.jpg",
    license:
      "seher sikandar, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Seanpenn1.jpg",
    multipleChoice: ["Patrick Dempsey", "John Hawkes", "Matthew Perry"],
    bio: "https://en.wikipedia.org/wiki/Sean_Penn",
  },
  {
    answers: ["Leonardo DiCaprio", "DiCaprio", "Leo", "Leo DeCaps", "DeCaps"],
    mustacheSrc: "./assets/mustaches/mustache(20).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/3f/Leonardo_DiCaprio_visited_Goddard_Saturday_to_discuss_Earth_science_with_Piers_Sellers_%2826105091624%29_cropped.jpg",
    license:
      "NASA/Goddard/Rebecca Roth, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Leonardo_DiCaprio_visited_Goddard_Saturday_to_discuss_Earth_science_with_Piers_Sellers_(26105091624)_cropped.jpg",
    multipleChoice: ["Brad Pitt", "Matt Damon", "Chad Michael Murray"],
    bio: "https://en.wikipedia.org/wiki/Leonardo_DiCaprio",
  },
  {
    answers: ["Sylvester Stallone"],
    mustacheSrc: "./assets/mustaches/mustache(88).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/c/c2/Flickr_-_nicogenin_-_66%C3%A8me_Festival_de_Venise_%28Mostra%29_-_Sylvester_Stallone_%2826%29.jpg",
    license:
      "nicolas genin from Paris, France, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Flickr_-_nicogenin_-_66%C3%A8me_Festival_de_Venise_(Mostra)_-_Sylvester_Stallone_(26).jpg",
    multipleChoice: ["Robert De Niro", "Al Pacino", "Arnold Schwarzenegger"],
    bio: "https://en.wikipedia.org/wiki/Sylvester_Stallone",
  },
  {
    answers: ["Paul Giamatti"],
    mustacheSrc: "./assets/mustaches/mustache(27).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/3/37/Paul_Giamatti_2024.jpg",
    license:
      "Raph_PH, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Paul_Giamatti_2024.jpg",
    multipleChoice: ["Dr Phil", "Andy Reid", "Jack Black"],
    bio: "https://en.wikipedia.org/wiki/Paul_Giamatti",
  },
  {
    answers: ["Walt Disney"],
    mustacheSrc: "./assets/mustaches/mustache(63).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/d/df/Walt_Disney_1946.JPG",
    license:
      "Boy Scouts of America, Public domain, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Walt_Disney_1946.JPG",
    multipleChoice: ["Paul Newman", "Burt Reynolds", "Charlie Chaplin"],
    bio: "https://en.wikipedia.org/wiki/Walt_Disney",
  },
  {
    answers: ["Mike Tyson"],
    mustacheSrc: "./assets/mustaches/mustache(36).png",
    fullSrc:
      "https://upload.wikimedia.org/wikipedia/commons/e/e1/Mike_Tyson_2019_by_Glenn_Francis.jpg",
    license:
      "Toglenn, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons",
    source: "https://commons.wikimedia.org/wiki/File:Mike_Tyson_2019_by_Glenn_Francis.jpg",
    multipleChoice: ["Bill Russell", "Morgan Freeman", "Jamie Foxx"],
    bio: "https://en.wikipedia.org/wiki/Mike_Tyson",
  },
];
